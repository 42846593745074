.wrapper {
  width: 100%;
  padding: 1rem 0rem 0rem 0rem;
  margin: 1rem 0;
}

.row {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.col_12 {
  flex: 0 0 auto;
  width: 100%;
}

.text_title {
  font-size: 27px !important;
  line-height: 40px !important; 
  letter-spacing: 0em !important;
  margin: 0 0 1rem 0 !important;
  padding: 0 1rem !important;
  font-weight: 800 !important;
  text-align: center !important;
  color: #1d3a60 !important;
}

.margin1 {
  margin: 1rem 0;
  margin-bottom: 60px;
}

.partnerCard {
  height: 70px;
  width: 168px;
  margin: 20px 0;
}

.partnerCard a {
  width: 100%;
  height: 100%;
  display: block;
  text-decoration: underline;
  text-decoration: none;
}

.partner_moz {
  background-image: url(../img/logos/MHU.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.partner_medimost {
  background-image: url(../img/logos/Medimost.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.partner_GOU {
  background-image: url(../img/logos/GU.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.partner_vuso {
  background-image: url(../img/logos/VUSO.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.partner_polandprize {
  background-image: url(../img/logos/PolandPrize.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.partner_USF {
  background-image: url(../img/logos/USF.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.partner_AC {
  background-image: url(../img/logos/AdamantCapital.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.partner_ITARENA {
  background-image: url(../img/logos/Arena.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.partner_JICA {
  background-image: url(../img/logos/JICA.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.partner_Amazon {
  background-image: url(../img/logos/AmazonLogo.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.partner_KSGC {
  background-image: url(../img/logos/KStartup.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.partner_Netrix {
  background-image: url(../img/logos/Netrix.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.partner_AAIC {
  background-image: url(../img/logos/AAIC.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.partner_PARP {
  background-image: url(../img/logos/parp.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.partner_CPP {
  background-image: url(../img/logos/CPP.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.partner_unia {
  background-image: url(../img/logos/unia.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.partner_fundasze {
  background-image: url(../img/logos/fundusze.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.partner_oranta {
  background-image: url(../img/logos/Oranta.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.partner_askep {
  background-image: url(../img/logos/Askep.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

@media (min-width: 768px) {
  .text_title {
    font-size: 40px !important;
  }

  .text_subtitle {
    line-height: 30px;
  }
}

@media (min-width: 992px) {
  .wrapper {
    margin-right: auto;
    margin-left: auto;
    max-width: 990px;
  }

  .text_title {
    line-height: 57px !important;
    max-width: 99% !important;
    padding: 0 !important;
  }

  .partnerCard {
    height: 90px;
    width: 260px;
    margin: 40px 0 20px 0;
  }

  .margin1 {
    margin-bottom: 120px;
  }
}

@media (min-width: 1200px) {
  .wrapper {
    max-width: 1200px;
  }
}
