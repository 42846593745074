@import url(https://fonts.googleapis.com/css2?family=Manrope:wght@200;400;700&display=swap);
body {
  margin: 0;
  font-family: "Manrope", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.Section3_wrapper__k6RcB {
  width: 100%;
  padding: 1rem 0rem;
  margin: 1rem 0;
  margin-bottom: 60px;
}

.Section3_text_title__2OnPA {
  font-size: 27px !important;
  line-height: 40px !important;
  font-weight: 800 !important;
  letter-spacing: 0em !important;
  margin: 0 0 1rem 0 !important;
  padding: 0 1rem !important;
  text-align: center !important;
  color: #ffffff !important;
}

.Section3_text_subtitle__1a0ZO {
  font-size: 16px !important;
  font-weight: 500 !important;
  line-height: 28px !important;
  letter-spacing: 0em !important;
  text-align: center !important;
  padding: 0 1rem !important;
  color: #ffffff !important;
}

.Section3_section_4__2qP9W {
  width: 100%;
  height: 700px;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-image: url(/static/media/section3_bg.374aab49.png);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  border-radius: 16px;
}

.Section3_section_4__2qP9W p {
  color: #ffffff;
}

.Section3_top__3mCDI {
  height: 35%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

.Section3_bottom__MfIwj {
  height: 45%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.Section3_img_s4__3uXpn {
  width: 180px;
  height: 180px;
  background-image: url(/static/media/search_image.973d106d.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  margin-left: 5%;
}

@media (min-width: 768px) {
  .Section3_text_title__2OnPA {
    font-size: 40px !important;
    margin-bottom: 1rem !important;
    line-height: 50px !important;
  }

  .Section3_top__3mCDI {
    height: 35%;
  }

  .Section3_bottom__MfIwj {
    height: 35%;
    display: flex;
  }

  .Section3_img_s4__3uXpn {
    width: 180px;
    height: 180px;
    margin-left: 10%;
  }

  .Section3_text_subtitle__1a0ZO {
    font-size: 20px !important;
  }
}

@media (min-width: 992px) {
  .Section3_wrapper__k6RcB {
    margin-right: auto;
    margin-left: auto;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
    width: 90%;
    max-width: 980px;
    margin-bottom: 120px;
  }

  .Section3_text_title__2OnPA {
    line-height: 57px !important;
    padding: 0 3rem !important;
  }

  .Section3_text_subtitle__1a0ZO {
    line-height: 30px !important;
    padding: 0 0 !important;
    font-size: 20px !important;
  }

  .Section3_section_4__2qP9W {
    border-radius: 65px;
    height: 570px;
  }

  .Section3_img_s4__3uXpn {
    width: 180px;
    height: 180px;
  }
}

@media (min-width: 1200px) {
  .Section3_text_title__2OnPA {
    line-height: 57px !important;
    padding: 0 5rem !important;
  }

  .Section3_wrapper__k6RcB {
    max-width: 1200px;
  }

  .Section3_text_subtitle__1a0ZO {
    font-size: 20px !important;
  }
}

.Section10_wrapper__3vKP5 {
  width: 100%;
  padding: 1rem 0rem 0rem 0rem;
  margin: 1rem 0;
}

.Section10_row__1eGZz {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.Section10_col_12__2zMQK {
  flex: 0 0 auto;
  width: 100%;
}

.Section10_text_title__1nCNl {
  font-size: 27px !important;
  line-height: 40px !important; 
  letter-spacing: 0em !important;
  margin: 0 0 1rem 0 !important;
  padding: 0 1rem !important;
  font-weight: 800 !important;
  text-align: center !important;
  color: #1d3a60 !important;
}

.Section10_margin1__1RNyt {
  margin: 1rem 0;
  margin-bottom: 60px;
}

.Section10_partnerCard__1uCD1 {
  height: 70px;
  width: 168px;
  margin: 20px 0;
}

.Section10_partnerCard__1uCD1 a {
  width: 100%;
  height: 100%;
  display: block;
  text-decoration: underline;
  text-decoration: none;
}

.Section10_partner_moz__3qKzF {
  background-image: url(/static/media/MHU.dac86e55.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.Section10_partner_medimost__2QWNk {
  background-image: url(/static/media/Medimost.c785c542.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.Section10_partner_GOU__36fcA {
  background-image: url(/static/media/GU.7ab2d60d.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.Section10_partner_vuso__3PkSx {
  background-image: url(/static/media/VUSO.9c9edc4e.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.Section10_partner_polandprize__2A3hs {
  background-image: url(/static/media/PolandPrize.2d748cb4.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.Section10_partner_USF__3QtPw {
  background-image: url(/static/media/USF.a310f4d6.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.Section10_partner_AC__2IWUC {
  background-image: url(/static/media/AdamantCapital.a0e424b4.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.Section10_partner_ITARENA__2VNXY {
  background-image: url(/static/media/Arena.eda01eb1.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.Section10_partner_JICA__2u-Tj {
  background-image: url(/static/media/JICA.4e7e4859.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.Section10_partner_Amazon__3WO0L {
  background-image: url(/static/media/AmazonLogo.e1d40054.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.Section10_partner_KSGC__1_VQb {
  background-image: url(/static/media/KStartup.a2d54c79.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.Section10_partner_Netrix__1lYci {
  background-image: url(/static/media/Netrix.8b2a631c.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.Section10_partner_AAIC__2YaRu {
  background-image: url(/static/media/AAIC.aab9667f.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.Section10_partner_PARP__1yfFv {
  background-image: url(/static/media/parp.ddc2958b.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.Section10_partner_CPP__3H5-G {
  background-image: url(/static/media/CPP.76e5efb4.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.Section10_partner_unia__3U-4Y {
  background-image: url(/static/media/unia.4445b639.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.Section10_partner_fundasze__3grhE {
  background-image: url(/static/media/fundusze.0b47f4f6.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.Section10_partner_oranta__2Lmvo {
  background-image: url(/static/media/Oranta.a0f6720b.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.Section10_partner_askep__1Ty4w {
  background-image: url(/static/media/Askep.f8e97beb.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

@media (min-width: 768px) {
  .Section10_text_title__1nCNl {
    font-size: 40px !important;
  }

  .Section10_text_subtitle__1oQX3 {
    line-height: 30px;
  }
}

@media (min-width: 992px) {
  .Section10_wrapper__3vKP5 {
    margin-right: auto;
    margin-left: auto;
    max-width: 990px;
  }

  .Section10_text_title__1nCNl {
    line-height: 57px !important;
    max-width: 99% !important;
    padding: 0 !important;
  }

  .Section10_partnerCard__1uCD1 {
    height: 90px;
    width: 260px;
    margin: 40px 0 20px 0;
  }

  .Section10_margin1__1RNyt {
    margin-bottom: 120px;
  }
}

@media (min-width: 1200px) {
  .Section10_wrapper__3vKP5 {
    max-width: 1200px;
  }
}

/**
 * Owl Carousel v2.3.4
 * Copyright 2013-2018 David Deutsch
 * Licensed under: SEE LICENSE IN https://github.com/OwlCarousel2/OwlCarousel2/blob/master/LICENSE
 */
/*
 *  Owl Carousel - Core
 */
.owl-carousel {
  display: none;
  width: 100%;
  -webkit-tap-highlight-color: transparent;
  /* position relative and z-index fix webkit rendering fonts issue */
  position: relative;
  z-index: 1;
}
.owl-carousel .owl-stage {
  position: relative;
  touch-action: manipulation;
  -moz-backface-visibility: hidden;
  /* fix firefox animation glitch */
}
.owl-carousel .owl-stage:after {
  content: ".";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0;
}
.owl-carousel .owl-stage-outer {
  position: relative;
  overflow: hidden;
  /* fix for flashing background */
  -webkit-transform: translate3d(0px, 0px, 0px);
}
.owl-carousel .owl-wrapper,
.owl-carousel .owl-item {
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
}
.owl-carousel .owl-item {
  position: relative;
  min-height: 1px;
  float: left;
  -webkit-backface-visibility: hidden;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
}
.owl-carousel .owl-item img {
  display: block;
  width: 100%;
}
.owl-carousel .owl-nav.disabled,
.owl-carousel .owl-dots.disabled {
  display: none;
}
.owl-carousel .owl-nav .owl-prev,
.owl-carousel .owl-nav .owl-next,
.owl-carousel .owl-dot {
  cursor: pointer;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.owl-carousel .owl-nav button.owl-prev,
.owl-carousel .owl-nav button.owl-next,
.owl-carousel button.owl-dot {
  background: none;
  color: inherit;
  border: none;
  padding: 0 !important;
  font: inherit;
}
.owl-carousel.owl-loaded {
  display: block;
}
.owl-carousel.owl-loading {
  opacity: 0;
  display: block;
}
.owl-carousel.owl-hidden {
  opacity: 0;
}
.owl-carousel.owl-refresh .owl-item {
  visibility: hidden;
}
.owl-carousel.owl-drag .owl-item {
  touch-action: pan-y;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.owl-carousel.owl-grab {
  cursor: move;
  cursor: grab;
}
.owl-carousel.owl-rtl {
  direction: rtl;
}
.owl-carousel.owl-rtl .owl-item {
  float: right;
}

/* No Js */
.no-js .owl-carousel {
  display: block;
}

/*
 *  Owl Carousel - Animate Plugin
 */
.owl-carousel .animated {
  -webkit-animation-duration: 1000ms;
          animation-duration: 1000ms;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
}

.owl-carousel .owl-animated-in {
  z-index: 0;
}

.owl-carousel .owl-animated-out {
  z-index: 1;
}

.owl-carousel .fadeOut {
  -webkit-animation-name: fadeOut;
          animation-name: fadeOut;
}

@-webkit-keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

/*
 * 	Owl Carousel - Auto Height Plugin
 */
.owl-height {
  transition: height 500ms ease-in-out;
}

/*
 * 	Owl Carousel - Lazy Load Plugin
 */
.owl-carousel .owl-item {
  /**
			This is introduced due to a bug in IE11 where lazy loading combined with autoheight plugin causes a wrong
			calculation of the height of the owl-item that breaks page layouts
		 */
}
.owl-carousel .owl-item .owl-lazy {
  opacity: 0;
  transition: opacity 400ms ease;
}
.owl-carousel .owl-item .owl-lazy[src^=""],
.owl-carousel .owl-item .owl-lazy:not([src]) {
  max-height: 0;
}
.owl-carousel .owl-item img.owl-lazy {
  -webkit-transform-style: preserve-3d;
          transform-style: preserve-3d;
}

/*
 * 	Owl Carousel - Video Plugin
 */
.owl-carousel .owl-video-wrapper {
  position: relative;
  height: 100%;
  background: #000;
}

.owl-carousel .owl-video-play-icon {
  position: absolute;
  height: 80px;
  width: 80px;
  left: 50%;
  top: 50%;
  margin-left: -40px;
  margin-top: -40px;
  /* background: url("owl.video.play.png") no-repeat; */
  cursor: pointer;
  z-index: 1;
  -webkit-backface-visibility: hidden;
  transition: -webkit-transform 100ms ease;
  transition: transform 100ms ease;
  transition: transform 100ms ease, -webkit-transform 100ms ease;
}

.owl-carousel .owl-video-play-icon:hover {
  -webkit-transform: scale(1.3, 1.3);
          transform: scale(1.3, 1.3);
}

.owl-carousel .owl-video-playing .owl-video-tn,
.owl-carousel .owl-video-playing .owl-video-play-icon {
  display: none;
}

.owl-carousel .owl-video-tn {
  opacity: 0;
  height: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  transition: opacity 400ms ease;
}

.owl-carousel .owl-video-frame {
  position: relative;
  z-index: 1;
  height: 100%;
  width: 100%;
}

@charset "UTF-8";

:root {
  --color-black: #3d3d3d;
  --color-second-blue: #5d9bf8;
  --color-dark-blue: #1d3a60;
  --color-white: #ffffff;
  --color-cards-dark: #f1f5ff;
  --color-stroke: #e4efff;
  --color-red: #ff5e5e;
  --color-hover-a: #5d9bf8;
  --color-grey: rgba(105, 119, 154, 1);
  --color-radial: radial-gradient(
    100.61% 100% at 0% 0%,
    #60a6ff 0%,
    #4f7df3 100%
  );
  --body-font-size: 1.125rem;
  --body-font-weight: 400;
  --body-line-height: 32px;
  --body-color: black;
  --body-text-align: left;
  --body-bg-white: #ffffff;
  --text-card-title-size: 1.5rem;
  --text-card-subtitle-size: 1rem;
  --text-futer-size: 0.875rem;
  --box-shadow-btn: 0px 67px 80px 0px rgba(101, 36, 239, 0.07);
  --gutter-x: 0;
  --gutter-y: 0;
}

.land *,
.land *::before,
.land *::after {
  box-sizing: border-box;
}

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth;
  }
}

.land {
  font-family: "Manrope", sans-serif;
  text-align: left;
  font-size: 1.125rem;
  line-height: 32px;
  font-weight: 400;
  color: black;
}

body {
  margin: 0;
  font-family: "Manrope", sans-serif;
  font-size: 1.125rem;
  font-size: var(--body-font-size);
  font-weight: 400;
  font-weight: var(--body-font-weight);
  line-height: 32px;
  line-height: var(--body-line-height);
  color: black;
  color: var(--body-color);
  text-align: left;
  text-align: var(--body-text-align);
  background-color: var(--body-bg);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  overflow-x: hidden;
}

.h1-land,
.h2-land {
  margin-top: 0;
  margin-bottom: 1rem;
  font-weight: 700;
  line-height: 36px;
  letter-spacing: 0em;
}

.h1-land {
  font-size: calc(1.375rem + 1.5vw);
}

@media (min-width: 1200px) {
  .land .h1-land {
    font-size: 2.5rem;
  }
}

.h2-land {
  font-size: calc(1.325rem + 0.9vw);
}

@media (min-width: 1200px) {
  .land .h2-land {
    font-size: 2rem;
  }
}

h3,
.h3 {
  font-size: calc(1.3rem + 0.6vw);
}

@media (min-width: 1200px) {
  h3,
  .land .h3 {
    font-size: 1.75rem;
  }
}

h4,
.h4 {
  font-size: calc(1.275rem + 0.3vw);
}

@media (min-width: 1200px) {
  h4,
  .land .h4 {
    font-size: 1.5rem;
  }
}

h5,
.h5 {
  font-size: 1.25rem;
}

h6,
.h6 {
  font-size: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

sub,
sup {
  font-size: 0.75em;
  line-height: 0;
  vertical-align: baseline;
}

.land a {
  color: #3d3d3d;
  color: var(--color-black);
  text-decoration: underline;
  text-decoration: none;
}

.land a:hover {
  color: #5d9bf8;
  color: var(--color-hover-a);
}

.land a:not([href]):not([class]),
.land a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

img,
svg {
  vertical-align: middle;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-size: inherit;
  line-height: inherit;
}

button,
select {
  text-transform: none;
}

[role="button"] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}

select:disabled {
  opacity: 1;
}

[list]::-webkit-calendar-picker-indicator {
  display: none;
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer;
}

::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

::-webkit-inner-spin-button {
  height: auto;
}

[hidden] {
  display: none !important;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}

.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.img-fluid {
  max-width: 80%;
  height: auto;
  margin: 1rem 0rem;
}

.container-land {
  width: 100%;
}

@media (min-width: 992px) {
  .land .container-land {
    margin-right: auto;
    margin-left: auto;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
    width: 87%;
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .land .container-land {
    max-width: 1140px;
  }
}

@media (min-width: 1400px) {
  .land .container-land {
    max-width: 1320px;
  }
}

.row-land {
  display: flex;
  flex-wrap: wrap;
}
.row-land > * {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
}

.col {
  flex: 1 0;
}

.row-land-cols-auto > * {
  flex: 0 0 auto;
  width: auto;
}

.row-land-cols-1 > * {
  flex: 0 0 auto;
  width: 100%;
}

.row-land-cols-2 > * {
  flex: 0 0 auto;
  width: 50%;
}

.row-land-cols-3 > * {
  flex: 0 0 auto;
  width: 33.3333333333%;
}

.row-land-cols-4 > * {
  flex: 0 0 auto;
  width: 25%;
}

.row-land-cols-5 > * {
  flex: 0 0 auto;
  width: 20%;
}

.row-land-cols-6 > * {
  flex: 0 0 auto;
  width: 16.6666666667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
}

.col-1 {
  flex: 0 0 auto;
  width: 8.33333333%;
}

.col-2 {
  flex: 0 0 auto;
  width: 16.66666667%;
}

.col-3 {
  flex: 0 0 auto;
  width: 25%;
}

.col-4 {
  flex: 0 0 auto;
  width: 33.33333333%;
}

.col-5 {
  flex: 0 0 auto;
  width: 41.66666667%;
}

.col-6 {
  flex: 0 0 auto;
  width: 50%;
}

.col-7 {
  flex: 0 0 auto;
  width: 58.33333333%;
}

.col-8 {
  flex: 0 0 auto;
  width: 66.66666667%;
}

.col-9 {
  flex: 0 0 auto;
  width: 75%;
}

.col-10 {
  flex: 0 0 auto;
  width: 83.33333333%;
}

.col-11 {
  flex: 0 0 auto;
  width: 91.66666667%;
}

.col-12 {
  flex: 0 0 auto;
  width: 100%;
}

@media (min-width: 576px) {
  .land .col-sm {
    flex: 1 0;
  }

  .land .row-land-cols-sm-auto > * {
    flex: 0 0 auto;
    width: auto;
  }

  .land .row-land-cols-sm-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }

  .land .row-land-cols-sm-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }

  .land .row-land-cols-sm-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .land .row-land-cols-sm-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }

  .land .row-land-cols-sm-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }

  .land .row-land-cols-sm-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .land .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .land .col-sm-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }

  .land .col-sm-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .land .col-sm-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .land .col-sm-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .land .col-sm-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .land .col-sm-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .land .col-sm-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  .land .col-sm-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .land .col-sm-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .land .col-sm-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }

  .land .col-sm-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }

  .land .col-sm-12 {
    flex: 0 0 auto;
    width: 100%;
  }
}

@media (min-width: 768px) {
  .land .col-md {
    flex: 1 0;
  }

  .land .row-land-cols-md-auto > * {
    flex: 0 0 auto;
    width: auto;
  }

  .land .row-land-cols-md-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }

  .land .row-land-cols-md-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }

  .land .row-land-cols-md-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .land .row-land-cols-md-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }

  .land .row-land-cols-md-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }

  .land .row-land-cols-md-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .land .col-md-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .land .col-md-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }

  .land .col-md-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .land .col-md-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .land .col-md-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .land .col-md-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .land .col-md-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .land .col-md-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  .land .col-md-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .land .col-md-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .land .col-md-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }

  .land .col-md-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }

  .land .col-md-12 {
    flex: 0 0 auto;
    width: 100%;
  }
}

@media (min-width: 992px) {
  .land .col-lg {
    flex: 1 0;
  }

  .land .row-land-cols-lg-auto > * {
    flex: 0 0 auto;
    width: auto;
  }

  .land .row-land-cols-lg-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }

  .land .row-land-cols-lg-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }

  .land .row-land-cols-lg-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .land .row-land-cols-lg-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }

  .land .row-land-cols-lg-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }

  .land .row-land-cols-lg-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .land .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .land .col-lg-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }

  .land .col-lg-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .land .col-lg-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .land .col-lg-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .land .col-lg-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .land .col-lg-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .land .col-lg-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  .land .col-lg-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .land .col-lg-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .land .col-lg-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }

  .land .col-lg-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }

  .land .col-lg-12 {
    flex: 0 0 auto;
    width: 100%;
  }
}

@media (min-width: 1200px) {
  .land .col-xl {
    flex: 1 0;
  }

  .land .row-land-cols-xl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }

  .land .row-land-cols-xl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }

  .land .row-land-cols-xl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }

  .land .row-land-cols-xl-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .land .row-land-cols-xl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }

  .land .row-land-cols-xl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }

  .land .row-land-cols-xl-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .land .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .land .col-xl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }

  .land .col-xl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .land .col-xl-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .land .col-xl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .land .col-xl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .land .col-xl-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .land .col-xl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  .land .col-xl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .land .col-xl-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .land .col-xl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }

  .land .col-xl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }

  .land .col-xl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
}

@media (min-width: 1400px) {
  .land .col-xxl {
    flex: 1 0;
  }

  .land .row-land-cols-xxl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }

  .land .row-land-cols-xxl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }

  .land .row-land-cols-xxl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }

  .land .row-land-cols-xxl-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .land .row-land-cols-xxl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }

  .land .row-land-cols-xxl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }

  .land .row-land-cols-xxl-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .land .col-xxl-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .land .col-xxl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }

  .land .col-xxl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .land .col-xxl-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .land .col-xxl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .land .col-xxl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .land .col-xxl-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .land .col-xxl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  .land .col-xxl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .land .col-xxl-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .land .col-xxl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }

  .land .col-xxl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }

  .land .col-xxl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
}

.btn-land {
  width: 182px;
  height: 42px;
  display: inline-block;
  line-height: 140%;
  letter-spacing: 0.22px;
  box-shadow: 0px 67px 80px 0px rgba(101, 36, 239, 0.07);
  box-shadow: var(--box-shadow-btn);
  padding: 9px 11px;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-size: 0.9rem;
  font-weight: 400;
  border-radius: 2.5rem;
  border: none;
}

.section-10-land .land .btn-land {
  font-weight: 700;
  width: 100%;
  padding-top: 16px;
  padding-bottom: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;
}

.section-10-land .land .btn-land a {
  font-size: 15px;
}

@media (min-width: 576px) {
  .land .section-10-land .land .btn-land {
    width: 30%;
  }
}

@media (min-width: 900px) {
  .land .section-10-land .land .btn-land {
    width: 35%;
  }
}

@media (prefers-reduced-motion: reduce) {
  .land .btn-land {
    transition: none;
  }
}

.land .btn-land:hover {
  background: var(--color-btn-hover);
  color: #3d3d3d;
  color: var(--color-black);
}

.btn-land:disabled,
.btn-land.disabled,
fieldset:disabled .land .btn-land {
  pointer-events: none;
  opacity: 0.65;
}

.btn-land:focus {
  color: #3d3d3d;
  color: var(--color-black);
  background-color: #ffffff;
  background-color: var(--color-white);
  border-color: #ffffff;
  border-color: var(--color-white);
}

.land .btn-link {
  width: 1.5rem;
  height: 1.5rem;
  background: var(--color-btn-link);
  color: var(--color-btn-link);
  text-decoration: underline;
}

.land .btn-link:hover {
  color: var(--color-btn-link-hover);
}

.land .btn-link:disabled,
.land .btn-link.disabled {
  background: var(--color-btn-link);
}

.land .fade {
  transition: opacity 0.15s linear;
}

@media (prefers-reduced-motion: reduce) {
  .land .fade {
    transition: none;
  }
}

.fade:not(.show) {
  opacity: 0;
}

.land .collapse:not(.show) {
  display: none;
}

.land .collapsing {
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}

@media (prefers-reduced-motion: reduce) {
  .land .collapsing {
    transition: none;
  }
}

.land .collapsing.collapse-horizontal {
  width: 0;
  height: auto;
  transition: width 0.35s ease;
}

@media (prefers-reduced-motion: reduce) {
  .land .collapsing.collapse-horizontal {
    transition: none;
  }
}

.dropup,
.dropend,
.dropstart {
  position: relative;
}

.land .nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.land .nav-link {
  display: block;
  padding: 0.5rem 1rem;
  color: #3d3d3d;
  color: var(--color-black);
  text-decoration: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .land .nav-link {
    transition: none;
  }
}

.land .nav-link:hover,
.land .nav-link:focus {
  color: #5d9bf8 !important;
  color: var(--color-second-blue) !important;
}

.land .nav-link.disabled {
  pointer-events: none;
  cursor: default;
}

.land .navbar {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.land .navbar > .land .container-land {
  display: flex;
  flex-wrap: inherit;
  align-items: center;
  justify-content: flex-end;
}

.land .navbar-brand {
  text-decoration: none;
  white-space: nowrap;
}

.land .navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.land .navbar-nav .land .nav-link {
  padding-right: 0;
  padding-left: 0;
}

.land .navbar-text {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.land .navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.land .navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.35rem;
  line-height: 1;
  background-color: transparent;
  border: none;
  transition: color 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .land .navbar-toggler {
    transition: none;
  }
}

.land .navbar-toggler:hover {
  text-decoration: none;
  color: #ff5e5e !important;
  color: var(--color-red) !important;
}

.land .navbar-toggler:focus {
  text-decoration: none;
  outline: 0;
  color: #ff5e5e !important;
  color: var(--color-red) !important;
}

.land .navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
}

.land .navbar-nav-scroll {
  max-height: var(75vh);
  overflow-y: auto;
}

@media (min-width: 576px) {
  .land .navbar-expand-sm {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  .land .navbar-expand-sm .land .navbar-nav {
    flex-direction: row;
  }

  .land .navbar-expand-sm .land .navbar-nav .land .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }

  .land .navbar-expand-sm .land .navbar-nav-scroll {
    overflow: visible;
  }

  .land .navbar-expand-sm .land .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }

  .land .navbar-expand-sm .land .navbar-toggler {
    display: none;
  }

  .land .navbar-expand-sm .land .offcanvas-header {
    display: none;
  }

  .land .navbar-expand-sm .land .offcanvas {
    bottom: 0;
    z-index: 1000;
    flex-grow: 1;
    visibility: visible !important;
    background-color: transparent;
    border-right: 0;
    border-left: 0;
    transition: none;
    -webkit-transform: none;
            transform: none;
  }

  .land .navbar-expand-sm .land .offcanvas-top,
  .land .navbar-expand-sm .land .offcanvas-bottom {
    height: auto;
    border-top: 0;
    border-bottom: 0;
  }

  .land .navbar-expand-sm .land .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}

@media (min-width: 768px) {
  .land .navbar-expand-md {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  .land .navbar-expand-md .land .navbar-nav {
    flex-direction: row;
  }

  .land .navbar-expand-md .land .navbar-nav .land .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }

  .land .navbar-expand-md .land .navbar-nav-scroll {
    overflow: visible;
  }

  .land .navbar-expand-md .land .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }

  .land .navbar-expand-md .land .navbar-toggler {
    display: none;
  }

  .land .navbar-expand-md .land .offcanvas-header {
    display: none;
  }

  .land .navbar-expand-md .land .offcanvas {
    bottom: 0;
    z-index: 1000;
    flex-grow: 1;
    visibility: visible !important;
    background-color: transparent;
    border-right: 0;
    border-left: 0;
    transition: none;
    -webkit-transform: none;
            transform: none;
  }

  .land .navbar-expand-md .land .offcanvas-top,
  .land .navbar-expand-md .land .offcanvas-bottom {
    height: auto;
    border-top: 0;
    border-bottom: 0;
  }

  .land .navbar-expand-md .land .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}

@media (min-width: 992px) {
  .land .navbar-expand-lg {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  .land .navbar-expand-lg .land .navbar-nav {
    flex-direction: row;
  }

  .land .navbar-expand-lg .land .navbar-nav .land .nav-link {
    padding-right: 0.7rem;
    padding-left: 0.7rem;
  }

  .land .navbar-expand-lg .land .navbar-nav-scroll {
    overflow: visible;
  }

  .land .navbar-expand-lg .land .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
    justify-content: flex-end;
  }

  .land .navbar-expand-lg .land .navbar-toggler {
    display: none;
  }

  .land .navbar-expand-lg .land .offcanvas-header {
    display: none;
  }

  .land .navbar-expand-lg .land .offcanvas {
    bottom: 0;
    z-index: 1000;
    flex-grow: 1;
    visibility: visible !important;
    background-color: transparent;
    border-right: 0;
    border-left: 0;
    transition: none;
    -webkit-transform: none;
            transform: none;
  }

  .land .navbar-expand-lg .land .offcanvas-top,
  .land .navbar-expand-lg .land .offcanvas-bottom {
    height: auto;
    border-top: 0;
    border-bottom: 0;
  }

  .land .navbar-expand-lg .land .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}

@media (min-width: 1200px) {
  .land .navbar-expand-xl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  .land .navbar-expand-xl .land .navbar-nav {
    flex-direction: row;
  }

  .land .navbar-expand-xl .land .navbar-nav .land .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }

  .land .navbar-expand-xl .land .navbar-nav-scroll {
    overflow: visible;
  }

  .land .navbar-expand-xl .land .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }

  .land .navbar-expand-xl .land .navbar-toggler {
    display: none;
  }

  .land .navbar-expand-xl .land .offcanvas-header {
    display: none;
  }

  .land .navbar-expand-lg .land .navbar-collapse {
    justify-content: space-between;
  }

  .land .navbar-expand-xl .land .offcanvas {
    bottom: 0;
    z-index: 1000;
    flex-grow: 1;
    visibility: visible !important;
    background-color: transparent;
    border-right: 0;
    border-left: 0;
    transition: none;
    -webkit-transform: none;
            transform: none;
  }

  .land .navbar-expand-xl .land .offcanvas-top,
  .land .navbar-expand-xl .land .offcanvas-bottom {
    height: auto;
    border-top: 0;
    border-bottom: 0;
  }

  .land .navbar-expand-xl .land .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}

@media (min-width: 1400px) {
  .land .navbar-expand-xxl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  .land .navbar-expand-xxl .land .navbar-nav {
    flex-direction: row;
  }

  .land .navbar-expand-xxl .land .navbar-nav .land .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }

  .land .navbar-expand-xxl .land .navbar-nav-scroll {
    overflow: visible;
  }

  .land .navbar-expand-xxl .land .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }

  .land .navbar-expand-xxl .land .navbar-toggler {
    display: none;
  }

  .land .navbar-expand-xxl .land .offcanvas-header {
    display: none;
  }

  .land .navbar-expand-xxl .land .offcanvas {
    bottom: 0;
    z-index: 1000;
    flex-grow: 1;
    visibility: visible !important;
    background-color: transparent;
    border-right: 0;
    border-left: 0;
    transition: none;
    -webkit-transform: none;
            transform: none;
  }

  .land .navbar-expand-xxl .land .offcanvas-top,
  .land .navbar-expand-xxl .land .offcanvas-bottom {
    height: auto;
    border-top: 0;
    border-bottom: 0;
  }

  .land .navbar-expand-xxl .land .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}

.land .navbar-expand {
  flex-wrap: nowrap;
  justify-content: flex-start;
}

.land .navbar-expand .land .navbar-nav {
  flex-direction: row;
}

.land .navbar-expand .land .navbar-nav .land .nav-link {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}

.land .navbar-expand .land .navbar-nav-scroll {
  overflow: visible;
}

.land .navbar-expand .land .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}

.land .navbar-expand .land .navbar-toggler {
  display: none;
}

.land .navbar-expand .land .offcanvas-header {
  display: none;
}

.land .navbar-expand .land .offcanvas {
  bottom: 0;
  z-index: 1000;
  flex-grow: 1;
  visibility: visible !important;
  background-color: transparent;
  border-right: 0;
  border-left: 0;
  transition: none;
  -webkit-transform: none;
          transform: none;
}

.land .navbar-expand .land .offcanvas-top,
.land .navbar-expand .land .offcanvas-bottom {
  height: auto;
  border-top: 0;
  border-bottom: 0;
}

.land .navbar-expand .land .offcanvas-body {
  display: flex;
  flex-grow: 0;
  padding: 0;
  overflow-y: visible;
}

.land .navbar-dark .land .navbar-nav .land .nav-link {
  color: #3d3d3d;
  color: var(--color-black);
  opacity: 0.9;
}

.land .navbar-dark .land .navbar-nav .land .nav-link.disabled {
  color: rgba(255, 255, 255, 0.25);
}

.land .navbar-dark .land .navbar-nav .land .show > .land .nav-link,
.land .navbar-dark .land .navbar-nav .land .nav-link.active {
  color: rgba(0, 0, 0, 0.9);
}

.land .navbar-dark .land .navbar-toggler {
  color: rgba(0, 0, 0, 0.9);
}

.land .navbar-dark .land .navbar-text {
  color: rgba(255, 255, 255, 0.55);
}

.land .navbar-dark .land .navbar-text a,
.land .navbar-dark .land .navbar-text a:hover,
.land .navbar-dark .land .navbar-text a:focus {
  color: #ffffff;
  color: var(--color-white);
}

.land .fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.land .fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

.land .sticky-top {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 1020;
}

@media (min-width: 576px) {
  .land .sticky-sm-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

@media (min-width: 768px) {
  .land .sticky-md-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

@media (min-width: 992px) {
  .land .sticky-lg-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

@media (min-width: 1200px) {
  .land .sticky-xl-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

@media (min-width: 1400px) {
  .land .sticky-xxl-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

.hstack {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: stretch;
}

.vstack {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  align-self: stretch;
}

.visually-hidden,
.visually-hidden-focusable:not(:focus):not(:focus-within) {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  content: "";
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.float-start {
  float: left !important;
}

.float-end {
  float: right !important;
}

.float-none {
  float: none !important;
}

.opacity-0 {
  opacity: 0 !important;
}

.opacity-25 {
  opacity: 0.25 !important;
}

.opacity-50 {
  opacity: 0.5 !important;
}

.opacity-75 {
  opacity: 0.75 !important;
}

.opacity-100 {
  opacity: 1 !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.overflow-visible {
  overflow: visible !important;
}

.overflow-scroll {
  overflow: scroll !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-grid {
  display: grid !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

.d-none {
  display: none !important;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: -webkit-sticky !important;
  position: sticky !important;
}

.top-0 {
  top: 0 !important;
}

.top-50 {
  top: 50% !important;
}

.top-100 {
  top: 100% !important;
}

.bottom-0 {
  bottom: 0 !important;
}

.bottom-50 {
  bottom: 50% !important;
}

.bottom-100 {
  bottom: 100% !important;
}

.start-0 {
  left: 0 !important;
}

.start-50 {
  left: 50% !important;
}

.start-100 {
  left: 100% !important;
}

.end-0 {
  right: 0 !important;
}

.end-50 {
  right: 50% !important;
}

.end-100 {
  right: 100% !important;
}

.border {
  border: 1px solid #3d3d3d !important;
  border: 1px solid var(--color-black) !important;
}

.border-0 {
  border: 0 !important;
}

.border-top {
  border-top: 1px solid #3d3d3d !important;
  border-top: 1px solid var(--color-black) !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-end {
  border-right: 1px solid #3d3d3d !important;
  border-right: 1px solid var(--color-black) !important;
}

.border-end-0 {
  border-right: 0 !important;
}

.border-bottom {
  border-bottom: 1px solid #3d3d3d !important;
  border-bottom: 1px solid var(--color-black) !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-start {
  border-left: 1px solid #3d3d3d !important;
  border-left: 1px solid var(--color-black) !important;
}

.border-start-0 {
  border-left: 0 !important;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.vw-100 {
  width: 100vw !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mh-100 {
  max-height: 100% !important;
}

.vh-100 {
  height: 100vh !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.gap-0 {
  grid-gap: 0 !important;
  gap: 0 !important;
}

.gap-1 {
  grid-gap: 0.25rem !important;
  gap: 0.25rem !important;
}

.gap-2 {
  grid-gap: 0.5rem !important;
  gap: 0.5rem !important;
}

.gap-3 {
  grid-gap: 1rem !important;
  gap: 1rem !important;
}

.gap-4 {
  grid-gap: 1.5rem !important;
  gap: 1.5rem !important;
}

.gap-5 {
  grid-gap: 3rem !important;
  gap: 3rem !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.justify-content-evenly {
  justify-content: space-evenly !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

.order-first {
  order: -1 !important;
}

.order-0 {
  order: 0 !important;
}

.order-1 {
  order: 1 !important;
}

.order-2 {
  order: 2 !important;
}

.order-3 {
  order: 3 !important;
}

.order-4 {
  order: 4 !important;
}

.order-5 {
  order: 5 !important;
}

.order-last {
  order: 6 !important;
}

.m-0 {
  margin: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.mx-1 {
  margin-right: 0.25rem !important;
  margin-left: 0.25rem !important;
}

.mx-2 {
  margin-right: 0.5rem !important;
  margin-left: 0.5rem !important;
}

.mx-3 {
  margin-right: 1rem !important;
  margin-left: 1rem !important;
}

.mx-4 {
  margin-right: 1.5rem !important;
  margin-left: 1.5rem !important;
}

.mx-5 {
  margin-right: 3rem !important;
  margin-left: 3rem !important;
}

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.my-1 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important;
}

.my-2 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}

.my-3 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.my-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.my-5 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-1 {
  margin-top: 0.25rem !important;
}

.mt-2 {
  margin-top: 0.5rem !important;
}

.mt-3 {
  margin-top: 1rem !important;
}

.mt-4 {
  margin-top: 1.5rem !important;
}

.mt-5 {
  margin-top: 3rem !important;
}

.mt-auto {
  margin-top: auto !important;
}

.me-0 {
  margin-right: 0 !important;
}

.me-1 {
  margin-right: 0.25rem !important;
}

.me-2 {
  margin-right: 0.5rem !important;
}

.me-3 {
  margin-right: 1rem !important;
}

.me-4 {
  margin-right: 1.5rem !important;
}

.me-5 {
  margin-right: 3rem !important;
}

.me-auto {
  margin-right: auto !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-1 {
  margin-bottom: 0.25rem !important;
}

.mb-2 {
  margin-bottom: 0.5rem !important;
}

.mb-3 {
  margin-bottom: 1rem !important;
}

.mb-4 {
  margin-bottom: 1.5rem !important;
}

.mb-5 {
  margin-bottom: 3rem !important;
}

.mb-auto {
  margin-bottom: auto !important;
}

.ms-0 {
  margin-left: 0 !important;
}

.ms-1 {
  margin-left: 0.25rem !important;
}

.ms-2 {
  margin-left: 0.5rem !important;
}

.ms-3 {
  margin-left: 1rem !important;
}

.ms-4 {
  margin-left: 1.5rem !important;
}

.ms-5 {
  margin-left: 3rem !important;
}

.ms-auto {
  margin-left: auto !important;
}

.p-0 {
  padding: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.px-1 {
  padding-right: 0.25rem !important;
  padding-left: 0.25rem !important;
}

.px-2 {
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important;
}

.px-3 {
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}

.px-4 {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important;
}

.px-5 {
  padding-right: 3rem !important;
  padding-left: 3rem !important;
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.py-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}

.py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.py-4 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.py-5 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pt-1 {
  padding-top: 0.25rem !important;
}

.pt-2 {
  padding-top: 0.5rem !important;
}

.pt-3 {
  padding-top: 1rem !important;
}

.pt-4 {
  padding-top: 1.5rem !important;
}

.pt-5 {
  padding-top: 3rem !important;
}

.pe-0 {
  padding-right: 0 !important;
}

.pe-1 {
  padding-right: 0.25rem !important;
}

.pe-2 {
  padding-right: 0.5rem !important;
}

.pe-3 {
  padding-right: 1rem !important;
}

.pe-4 {
  padding-right: 1.5rem !important;
}

.pe-5 {
  padding-right: 3rem !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pb-1 {
  padding-bottom: 0.25rem !important;
}

.pb-2 {
  padding-bottom: 0.5rem !important;
}

.pb-3 {
  padding-bottom: 1rem !important;
}

.pb-4 {
  padding-bottom: 1.5rem !important;
}

.pb-5 {
  padding-bottom: 3rem !important;
}

.ps-0 {
  padding-left: 0 !important;
}

.ps-1 {
  padding-left: 0.25rem !important;
}

.ps-2 {
  padding-left: 0.5rem !important;
}

.ps-3 {
  padding-left: 1rem !important;
}

.ps-4 {
  padding-left: 1.5rem !important;
}

.ps-5 {
  padding-left: 3rem !important;
}

.fst-italic {
  font-style: italic !important;
}

.fst-normal {
  font-style: normal !important;
}

.fw-light {
  font-weight: 300 !important;
}

.fw-normal {
  font-weight: 400 !important;
}

.fw-bold {
  font-weight: 700 !important;
}

.text-start {
  text-align: left !important;
}

.text-end {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-decoration-underline {
  text-decoration: underline !important;
}

.text-decoration-line-through {
  text-decoration: line-through !important;
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-grey-land {
  color: rgba(105, 119, 154, 1) !important;
  color: var(--color-grey) !important;
}

.text-white-land {
  color: #ffffff !important;
  color: var(--color-white) !important;
}

.text-blue-land {
  color: #5d9bf8 !important;
  color: var(--color-second-blue) !important;
}

.text-dark-blue-land {
  color: #1d3a60 !important;
  color: var(--color-dark-blue) !important;
}

.bg-red-land {
  background: #ff5e5e !important;
  background: var(--color-red) !important;
}

.bg-white-land {
  background: #ffffff !important;
  background: var(--color-white) !important;
}

@media (min-width: 576px) {
  .land .d-sm-inline {
    display: inline !important;
  }

  .land .d-sm-inline-block {
    display: inline-block !important;
  }

  .land .d-sm-block {
    display: block !important;
  }

  .land .d-sm-grid {
    display: grid !important;
  }

  .land .d-sm-table {
    display: table !important;
  }

  .land .d-sm-table-row {
    display: table-row !important;
  }

  .land .d-sm-table-cell {
    display: table-cell !important;
  }

  .land .d-sm-flex {
    display: flex !important;
  }

  .land .d-sm-inline-flex {
    display: inline-flex !important;
  }

  .land .d-sm-none {
    display: none !important;
  }

  .land .flex-sm-fill {
    flex: 1 1 auto !important;
  }

  .land .flex-sm-row {
    flex-direction: row !important;
  }

  .land .flex-sm-column {
    flex-direction: column !important;
  }

  .land .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }

  .land .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }

  .land .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }

  .land .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }

  .land .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }

  .land .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }

  .land .flex-sm-wrap {
    flex-wrap: wrap !important;
  }

  .land .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }

  .land .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .land .justify-content-sm-start {
    justify-content: flex-start !important;
  }

  .land .justify-content-sm-end {
    justify-content: flex-end !important;
  }

  .land .justify-content-sm-center {
    justify-content: center !important;
  }

  .land .justify-content-sm-between {
    justify-content: space-between !important;
  }

  .land .justify-content-sm-around {
    justify-content: space-around !important;
  }

  .land .justify-content-sm-evenly {
    justify-content: space-evenly !important;
  }

  .land .align-items-sm-start {
    align-items: flex-start !important;
  }

  .land .align-items-sm-end {
    align-items: flex-end !important;
  }

  .land .align-items-sm-center {
    align-items: center !important;
  }

  .land .align-items-sm-baseline {
    align-items: baseline !important;
  }

  .land .align-items-sm-stretch {
    align-items: stretch !important;
  }

  .land .align-content-sm-start {
    align-content: flex-start !important;
  }

  .land .align-content-sm-end {
    align-content: flex-end !important;
  }

  .land .align-content-sm-center {
    align-content: center !important;
  }

  .land .align-content-sm-between {
    align-content: space-between !important;
  }

  .land .align-content-sm-around {
    align-content: space-around !important;
  }

  .land .align-content-sm-stretch {
    align-content: stretch !important;
  }

  .land .align-self-sm-auto {
    align-self: auto !important;
  }

  .land .align-self-sm-start {
    align-self: flex-start !important;
  }

  .land .align-self-sm-end {
    align-self: flex-end !important;
  }

  .land .align-self-sm-center {
    align-self: center !important;
  }

  .land .align-self-sm-baseline {
    align-self: baseline !important;
  }

  .land .align-self-sm-stretch {
    align-self: stretch !important;
  }

  .land .m-sm-0 {
    margin: 0 !important;
  }

  .land .m-sm-1 {
    margin: 0.25rem !important;
  }

  .land .m-sm-2 {
    margin: 0.5rem !important;
  }

  .land .m-sm-3 {
    margin: 1rem !important;
  }

  .land .m-sm-4 {
    margin: 1.5rem !important;
  }

  .land .m-sm-5 {
    margin: 3rem !important;
  }

  .land .m-sm-auto {
    margin: auto !important;
  }

  .land .mx-sm-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .land .mx-sm-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .land .mx-sm-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .land .mx-sm-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .land .mx-sm-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .land .mx-sm-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .land .mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .land .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .land .my-sm-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .land .my-sm-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .land .my-sm-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .land .my-sm-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .land .my-sm-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .land .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .land .mt-sm-0 {
    margin-top: 0 !important;
  }

  .land .mt-sm-1 {
    margin-top: 0.25rem !important;
  }

  .land .mt-sm-2 {
    margin-top: 0.5rem !important;
  }

  .land .mt-sm-3 {
    margin-top: 1rem !important;
  }

  .land .mt-sm-4 {
    margin-top: 1.5rem !important;
  }

  .land .mt-sm-5 {
    margin-top: 3rem !important;
  }

  .land .mt-sm-auto {
    margin-top: auto !important;
  }

  .land .me-sm-0 {
    margin-right: 0 !important;
  }

  .land .me-sm-1 {
    margin-right: 0.25rem !important;
  }

  .land .me-sm-2 {
    margin-right: 0.5rem !important;
  }

  .land .me-sm-3 {
    margin-right: 1rem !important;
  }

  .land .me-sm-4 {
    margin-right: 1.5rem !important;
  }

  .land .me-sm-5 {
    margin-right: 3rem !important;
  }

  .land .me-sm-auto {
    margin-right: auto !important;
  }

  .land .mb-sm-0 {
    margin-bottom: 0 !important;
  }

  .land .mb-sm-1 {
    margin-bottom: 0.25rem !important;
  }

  .land .mb-sm-2 {
    margin-bottom: 0.5rem !important;
  }

  .land .mb-sm-3 {
    margin-bottom: 1rem !important;
  }

  .land .mb-sm-4 {
    margin-bottom: 1.5rem !important;
  }

  .land .mb-sm-5 {
    margin-bottom: 3rem !important;
  }

  .land .mb-sm-auto {
    margin-bottom: auto !important;
  }

  .land .ms-sm-0 {
    margin-left: 0 !important;
  }

  .land .ms-sm-1 {
    margin-left: 0.25rem !important;
  }

  .land .ms-sm-2 {
    margin-left: 0.5rem !important;
  }

  .land .ms-sm-3 {
    margin-left: 1rem !important;
  }

  .land .ms-sm-4 {
    margin-left: 1.5rem !important;
  }

  .land .ms-sm-5 {
    margin-left: 3rem !important;
  }

  .land .ms-sm-auto {
    margin-left: auto !important;
  }

  .land .p-sm-0 {
    padding: 0 !important;
  }

  .land .p-sm-1 {
    padding: 0.25rem !important;
  }

  .land .p-sm-2 {
    padding: 0.5rem !important;
  }

  .land .p-sm-3 {
    padding: 1rem !important;
  }

  .land .p-sm-4 {
    padding: 1.5rem !important;
  }

  .land .p-sm-5 {
    padding: 3rem !important;
  }

  .land .px-sm-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .land .px-sm-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .land .px-sm-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .land .px-sm-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .land .px-sm-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .land .px-sm-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .land .px-sm-6 {
    padding-right: 3rem !important;
    padding-left: 7rem !important;
  }

  .land .px-sm-7 {
    padding-right: 5rem !important;
    padding-left: 5rem !important;
  }

  .land .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .land .py-sm-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .land .py-sm-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .land .py-sm-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .land .py-sm-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .land .py-sm-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .land .pt-sm-0 {
    padding-top: 0 !important;
  }

  .land .pt-sm-1 {
    padding-top: 0.25rem !important;
  }

  .land .pt-sm-2 {
    padding-top: 0.5rem !important;
  }

  .land .pt-sm-3 {
    padding-top: 1rem !important;
  }

  .land .pt-sm-4 {
    padding-top: 1.5rem !important;
  }

  .land .pt-sm-5 {
    padding-top: 3rem !important;
  }

  .land .pe-sm-0 {
    padding-right: 0 !important;
  }

  .land .pe-sm-1 {
    padding-right: 0.25rem !important;
  }

  .land .pe-sm-2 {
    padding-right: 0.5rem !important;
  }

  .land .pe-sm-3 {
    padding-right: 1rem !important;
  }

  .land .pe-sm-4 {
    padding-right: 1.5rem !important;
  }

  .land .pe-sm-5 {
    padding-right: 3rem !important;
  }
  .land .pe-sm-6 {
    padding-right: 5rem !important;
  }
  .land .pb-sm-0 {
    padding-bottom: 0 !important;
  }

  .land .pb-sm-1 {
    padding-bottom: 0.25rem !important;
  }

  .land .pb-sm-2 {
    padding-bottom: 0.5rem !important;
  }

  .land .pb-sm-3 {
    padding-bottom: 1rem !important;
  }

  .land .pb-sm-4 {
    padding-bottom: 1.5rem !important;
  }

  .land .pb-sm-5 {
    padding-bottom: 3rem !important;
  }

  .land .ps-sm-0 {
    padding-left: 0 !important;
  }

  .land .ps-sm-1 {
    padding-left: 0.25rem !important;
  }

  .land .ps-sm-2 {
    padding-left: 0.5rem !important;
  }

  .land .ps-sm-3 {
    padding-left: 1rem !important;
  }

  .land .ps-sm-4 {
    padding-left: 1.5rem !important;
  }

  .land .ps-sm-5 {
    padding-left: 3rem !important;
  }

  .land .text-sm-start {
    text-align: left !important;
  }

  .land .text-sm-end {
    text-align: right !important;
  }

  .land .text-sm-center {
    text-align: center !important;
  }
}

@media (min-width: 768px) {
  .land .d-md-inline {
    display: inline !important;
  }

  .land .d-md-inline-block {
    display: inline-block !important;
  }

  .land .d-md-block {
    display: block !important;
  }

  .land .d-md-grid {
    display: grid !important;
  }

  .land .d-md-table {
    display: table !important;
  }

  .land .d-md-table-row {
    display: table-row !important;
  }

  .land .d-md-table-cell {
    display: table-cell !important;
  }

  .land .d-md-flex {
    display: flex !important;
  }

  .land .d-md-inline-flex {
    display: inline-flex !important;
  }

  .land .d-md-none {
    display: none !important;
  }

  .land .flex-md-fill {
    flex: 1 1 auto !important;
  }

  .land .flex-md-row {
    flex-direction: row !important;
  }

  .land .flex-md-column {
    flex-direction: column !important;
  }

  .land .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }

  .land .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }

  .land .flex-md-grow-0 {
    flex-grow: 0 !important;
  }

  .land .flex-md-grow-1 {
    flex-grow: 1 !important;
  }

  .land .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }

  .land .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }

  .land .flex-md-wrap {
    flex-wrap: wrap !important;
  }

  .land .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }

  .land .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .land .gap-md-0 {
    grid-gap: 0 !important;
    gap: 0 !important;
  }

  .land .gap-md-1 {
    grid-gap: 0.25rem !important;
    gap: 0.25rem !important;
  }

  .land .gap-md-2 {
    grid-gap: 0.5rem !important;
    gap: 0.5rem !important;
  }

  .land .gap-md-3 {
    grid-gap: 1rem !important;
    gap: 1rem !important;
  }

  .land .gap-md-4 {
    grid-gap: 1.5rem !important;
    gap: 1.5rem !important;
  }

  .land .gap-md-5 {
    grid-gap: 3rem !important;
    gap: 3rem !important;
  }

  .land .justify-content-md-start {
    justify-content: flex-start !important;
  }

  .land .justify-content-md-end {
    justify-content: flex-end !important;
  }

  .land .justify-content-md-center {
    justify-content: center !important;
  }

  .land .justify-content-md-between {
    justify-content: space-between !important;
  }

  .land .justify-content-md-around {
    justify-content: space-around !important;
  }

  .land .justify-content-md-evenly {
    justify-content: space-evenly !important;
  }

  .land .align-items-md-start {
    align-items: flex-start !important;
  }

  .land .align-items-md-end {
    align-items: flex-end !important;
  }

  .land .align-items-md-center {
    align-items: center !important;
  }

  .land .align-items-md-baseline {
    align-items: baseline !important;
  }

  .land .align-items-md-stretch {
    align-items: stretch !important;
  }

  .land .align-content-md-start {
    align-content: flex-start !important;
  }

  .land .align-content-md-end {
    align-content: flex-end !important;
  }

  .land .align-content-md-center {
    align-content: center !important;
  }

  .land .align-content-md-between {
    align-content: space-between !important;
  }

  .land .align-content-md-around {
    align-content: space-around !important;
  }

  .land .align-content-md-stretch {
    align-content: stretch !important;
  }

  .land .align-self-md-auto {
    align-self: auto !important;
  }

  .land .align-self-md-start {
    align-self: flex-start !important;
  }

  .land .align-self-md-end {
    align-self: flex-end !important;
  }

  .land .align-self-md-center {
    align-self: center !important;
  }

  .land .align-self-md-baseline {
    align-self: baseline !important;
  }

  .land .align-self-md-stretch {
    align-self: stretch !important;
  }

  .land .order-md-first {
    order: -1 !important;
  }

  .land .order-md-0 {
    order: 0 !important;
  }

  .land .order-md-1 {
    order: 1 !important;
  }

  .land .order-md-2 {
    order: 2 !important;
  }

  .land .order-md-3 {
    order: 3 !important;
  }

  .land .order-md-4 {
    order: 4 !important;
  }

  .land .order-md-5 {
    order: 5 !important;
  }

  .land .order-md-last {
    order: 6 !important;
  }

  .land .m-md-0 {
    margin: 0 !important;
  }

  .land .m-md-1 {
    margin: 0.25rem !important;
  }

  .land .m-md-2 {
    margin: 0.5rem !important;
  }

  .land .m-md-3 {
    margin: 1rem !important;
  }

  .land .m-md-4 {
    margin: 1.5rem !important;
  }

  .land .m-md-5 {
    margin: 3rem !important;
  }

  .land .m-md-auto {
    margin: auto !important;
  }

  .land .mx-md-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .land .mx-md-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .land .mx-md-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .land .mx-md-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .land .mx-md-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .land .mx-md-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .land .mx-md-6 {
    margin-right: 6rem !important;
    margin-left: 6rem !important;
  }
  .land .mx-md-8 {
    margin-right: 8rem !important;
    margin-left: 8rem !important;
  }

  .land .mx-md-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .land .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .land .my-md-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .land .my-md-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .land .my-md-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .land .my-md-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .land .my-md-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .land .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .land .mt-md-0 {
    margin-top: 0 !important;
  }

  .land .mt-md-1 {
    margin-top: 0.25rem !important;
  }

  .land .mt-md-2 {
    margin-top: 0.5rem !important;
  }

  .land .mt-md-3 {
    margin-top: 1rem !important;
  }

  .land .mt-md-4 {
    margin-top: 1.5rem !important;
  }

  .land .mt-md-5 {
    margin-top: 3rem !important;
  }

  .land .mt-md-6 {
    margin-top: 5rem !important;
  }

  .land .mt-md-auto {
    margin-top: auto !important;
  }

  .land .me-md-0 {
    margin-right: 0 !important;
  }

  .land .me-md-1 {
    margin-right: 0.25rem !important;
  }

  .land .me-md-2 {
    margin-right: 0.5rem !important;
  }

  .land .me-md-3 {
    margin-right: 1rem !important;
  }

  .land .me-md-4 {
    margin-right: 1.5rem !important;
  }

  .land .me-md-5 {
    margin-right: 3rem !important;
  }

  .land .me-md-auto {
    margin-right: auto !important;
  }

  .land .mb-md-0 {
    margin-bottom: 0 !important;
  }

  .land .mb-md-1 {
    margin-bottom: 0.25rem !important;
  }

  .land .mb-md-2 {
    margin-bottom: 0.5rem !important;
  }

  .land .mb-md-3 {
    margin-bottom: 1rem !important;
  }

  .land .mb-md-4 {
    margin-bottom: 1.5rem !important;
  }

  .land .mb-md-5 {
    margin-bottom: 3rem !important;
  }

  .land .mb-md-auto {
    margin-bottom: auto !important;
  }

  .land .ms-md-0 {
    margin-left: 0 !important;
  }

  .land .ms-md-1 {
    margin-left: 0.25rem !important;
  }

  .land .ms-md-2 {
    margin-left: 0.5rem !important;
  }

  .land .ms-md-3 {
    margin-left: 1rem !important;
  }

  .land .ms-md-4 {
    margin-left: 1.5rem !important;
  }

  .land .ms-md-5 {
    margin-left: 3rem !important;
  }

  .land .ms-md-auto {
    margin-left: auto !important;
  }

  .land .p-md-0 {
    padding: 0 !important;
  }

  .land .p-md-1 {
    padding: 0.25rem !important;
  }

  .land .p-md-2 {
    padding: 0.5rem !important;
  }

  .land .p-md-3 {
    padding: 1rem !important;
  }

  .land .p-md-4 {
    padding: 1.5rem !important;
  }

  .land .p-md-5 {
    padding: 3rem !important;
  }

  .land .px-md-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .land .px-md-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .land .px-md-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .land .px-md-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .land .px-md-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .land .px-md-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .land .px-md-7 {
    padding-right: 5rem !important;
    padding-left: 5rem !important;
  }

  .land .px-md-10 {
    padding-right: 10rem !important;
    padding-left: 10rem !important;
  }

  .land .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .land .py-md-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .land .py-md-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .land .py-md-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .land .py-md-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .land .py-md-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .land .pt-md-0 {
    padding-top: 0 !important;
  }

  .land .pt-md-1 {
    padding-top: 0.25rem !important;
  }

  .land .pt-md-2 {
    padding-top: 0.5rem !important;
  }

  .land .pt-md-3 {
    padding-top: 1rem !important;
  }

  .land .pt-md-4 {
    padding-top: 1.5rem !important;
  }

  .land .pt-md-5 {
    padding-top: 3rem !important;
  }

  .land .pe-md-0 {
    padding-right: 0 !important;
  }

  .land .pe-md-1 {
    padding-right: 0.25rem !important;
  }

  .land .pe-md-2 {
    padding-right: 0.5rem !important;
  }

  .land .pe-md-3 {
    padding-right: 1rem !important;
  }

  .land .pe-md-4 {
    padding-right: 1.5rem !important;
  }

  .land .pe-md-5 {
    padding-right: 3rem !important;
  }

  .land .pb-md-0 {
    padding-bottom: 0 !important;
  }

  .land .pb-md-1 {
    padding-bottom: 0.25rem !important;
  }

  .land .pb-md-2 {
    padding-bottom: 0.5rem !important;
  }

  .land .pb-md-3 {
    padding-bottom: 1rem !important;
  }

  .land .pb-md-4 {
    padding-bottom: 1.5rem !important;
  }

  .land .pb-md-5 {
    padding-bottom: 3rem !important;
  }

  .land .ps-md-0 {
    padding-left: 0 !important;
  }

  .land .ps-md-1 {
    padding-left: 0.25rem !important;
  }

  .land .ps-md-2 {
    padding-left: 0.5rem !important;
  }

  .land .ps-md-3 {
    padding-left: 1rem !important;
  }

  .land .ps-md-4 {
    padding-left: 1.5rem !important;
  }

  .land .ps-md-5 {
    padding-left: 3rem !important;
  }

  .land .text-md-start {
    text-align: left !important;
  }

  .land .text-md-end {
    text-align: right !important;
  }

  .land .text-md-center {
    text-align: center !important;
  }
}

@media (min-width: 992px) {
  .land .d-lg-inline {
    display: inline !important;
  }

  .land .d-lg-inline-block {
    display: inline-block !important;
  }

  .land .d-lg-block {
    display: block !important;
  }

  .land .d-lg-grid {
    display: grid !important;
  }

  .land .d-lg-table {
    display: table !important;
  }

  .land .d-lg-table-row {
    display: table-row !important;
  }

  .land .d-lg-table-cell {
    display: table-cell !important;
  }

  .land .d-lg-flex {
    display: flex !important;
  }

  .land .d-lg-inline-flex {
    display: inline-flex !important;
  }

  .land .d-lg-none {
    display: none !important;
  }

  .land .flex-lg-fill {
    flex: 1 1 auto !important;
  }

  .land .flex-lg-row {
    flex-direction: row !important;
  }

  .land .flex-lg-column {
    flex-direction: column !important;
  }

  .land .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }

  .land .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }

  .land .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }

  .land .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }

  .land .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }

  .land .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }

  .land .flex-lg-wrap {
    flex-wrap: wrap !important;
  }

  .land .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }

  .land .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .land .gap-lg-0 {
    grid-gap: 0 !important;
    gap: 0 !important;
  }

  .land .gap-lg-1 {
    grid-gap: 0.25rem !important;
    gap: 0.25rem !important;
  }

  .land .gap-lg-2 {
    grid-gap: 0.5rem !important;
    gap: 0.5rem !important;
  }

  .land .gap-lg-3 {
    grid-gap: 1rem !important;
    gap: 1rem !important;
  }

  .land .gap-lg-4 {
    grid-gap: 1.5rem !important;
    gap: 1.5rem !important;
  }

  .land .gap-lg-5 {
    grid-gap: 3rem !important;
    gap: 3rem !important;
  }

  .land .justify-content-lg-start {
    justify-content: flex-start !important;
  }

  .land .justify-content-lg-end {
    justify-content: flex-end !important;
  }

  .land .justify-content-lg-center {
    justify-content: center !important;
  }

  .land .justify-content-lg-between {
    justify-content: space-between !important;
  }

  .land .justify-content-lg-around {
    justify-content: space-around !important;
  }

  .land .justify-content-lg-evenly {
    justify-content: space-evenly !important;
  }

  .land .align-items-lg-start {
    align-items: flex-start !important;
  }

  .land .align-items-lg-end {
    align-items: flex-end !important;
  }

  .land .align-items-lg-center {
    align-items: center !important;
  }

  .land .align-items-lg-baseline {
    align-items: baseline !important;
  }

  .land .align-items-lg-stretch {
    align-items: stretch !important;
  }

  .land .align-content-lg-start {
    align-content: flex-start !important;
  }

  .land .align-content-lg-end {
    align-content: flex-end !important;
  }

  .land .align-content-lg-center {
    align-content: center !important;
  }

  .land .align-content-lg-between {
    align-content: space-between !important;
  }

  .land .align-content-lg-around {
    align-content: space-around !important;
  }

  .land .align-content-lg-stretch {
    align-content: stretch !important;
  }

  .land .align-self-lg-auto {
    align-self: auto !important;
  }

  .land .align-self-lg-start {
    align-self: flex-start !important;
  }

  .land .align-self-lg-end {
    align-self: flex-end !important;
  }

  .land .align-self-lg-center {
    align-self: center !important;
  }

  .land .align-self-lg-baseline {
    align-self: baseline !important;
  }

  .land .align-self-lg-stretch {
    align-self: stretch !important;
  }

  .land .order-lg-first {
    order: -1 !important;
  }

  .land .order-lg-0 {
    order: 0 !important;
  }

  .land .order-lg-1 {
    order: 1 !important;
  }

  .land .order-lg-2 {
    order: 2 !important;
  }

  .land .order-lg-3 {
    order: 3 !important;
  }

  .land .order-lg-4 {
    order: 4 !important;
  }

  .land .order-lg-5 {
    order: 5 !important;
  }

  .land .order-lg-last {
    order: 6 !important;
  }

  .land .m-lg-0 {
    margin: 0 !important;
  }

  .land .m-lg-1 {
    margin: 0.25rem !important;
  }

  .land .m-lg-2 {
    margin: 0.5rem !important;
  }

  .land .m-lg-3 {
    margin: 1rem !important;
  }

  .land .m-lg-4 {
    margin: 1.5rem !important;
  }

  .land .m-lg-5 {
    margin: 3rem !important;
  }

  .land .m-lg-auto {
    margin: auto !important;
  }

  .land .mx-lg-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .land .mx-lg-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .land .mx-lg-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .land .mx-lg-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .land .mx-lg-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .land .mx-lg-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .land .mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .land .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .land .my-lg-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .land .my-lg-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .land .my-lg-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .land .my-lg-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .land .my-lg-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .land .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .land .mt-lg-0 {
    margin-top: 0 !important;
  }

  .land .mt-lg-1 {
    margin-top: 0.25rem !important;
  }

  .land .mt-lg-2 {
    margin-top: 0.5rem !important;
  }

  .land .mt-lg-3 {
    margin-top: 1rem !important;
  }

  .land .mt-lg-4 {
    margin-top: 1.5rem !important;
  }

  .land .mt-lg-5 {
    margin-top: 3rem !important;
  }

  .land .mt-lg-6 {
    margin-top: 7rem !important;
  }

  .land .mt-lg-auto {
    margin-top: auto !important;
  }

  .land .me-lg-0 {
    margin-right: 0 !important;
  }

  .land .me-lg-1 {
    margin-right: 0.25rem !important;
  }

  .land .me-lg-2 {
    margin-right: 0.5rem !important;
  }

  .land .me-lg-3 {
    margin-right: 1rem !important;
  }

  .land .me-lg-4 {
    margin-right: 1.5rem !important;
  }

  .land .me-lg-5 {
    margin-right: 3rem !important;
  }

  .land .me-lg-auto {
    margin-right: auto !important;
  }

  .land .mb-lg-0 {
    margin-bottom: 0 !important;
  }

  .land .mb-lg-1 {
    margin-bottom: 0.25rem !important;
  }

  .land .mb-lg-2 {
    margin-bottom: 0.5rem !important;
  }

  .land .mb-lg-3 {
    margin-bottom: 1rem !important;
  }

  .land .mb-lg-4 {
    margin-bottom: 1.5rem !important;
  }

  .land .mb-lg-5 {
    margin-bottom: 3rem !important;
  }

  .land .mb-lg-auto {
    margin-bottom: auto !important;
  }

  .land .ms-lg-0 {
    margin-left: 0 !important;
  }

  .land .ms-lg-1 {
    margin-left: 0.25rem !important;
  }

  .land .ms-lg-2 {
    margin-left: 0.5rem !important;
  }

  .land .ms-lg-3 {
    margin-left: 1rem !important;
  }

  .land .ms-lg-4 {
    margin-left: 1.5rem !important;
  }

  .land .ms-lg-5 {
    margin-left: 3rem !important;
  }

  .land .ms-lg-auto {
    margin-left: auto !important;
  }

  .land .p-lg-0 {
    padding: 0 !important;
  }

  .land .p-lg-1 {
    padding: 0.25rem !important;
  }

  .land .p-lg-2 {
    padding: 0.5rem !important;
  }

  .land .p-lg-3 {
    padding: 1rem !important;
  }

  .land .p-lg-4 {
    padding: 1.5rem !important;
  }

  .land .p-lg-5 {
    padding: 3rem !important;
  }

  .land .px-lg-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .land .px-lg-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .land .px-lg-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .land .px-lg-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .land .px-lg-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .land .px-lg-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .land .px-lg-7 {
    padding-right: 7rem !important;
    padding-left: 7rem !important;
  }

  .land .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .land .py-lg-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .land .py-lg-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .land .py-lg-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .land .py-lg-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .land .py-lg-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .land .pt-lg-0 {
    padding-top: 0 !important;
  }

  .land .pt-lg-1 {
    padding-top: 0.25rem !important;
  }

  .land .pt-lg-2 {
    padding-top: 0.5rem !important;
  }

  .land .pt-lg-3 {
    padding-top: 1rem !important;
  }

  .land .pt-lg-4 {
    padding-top: 1.5rem !important;
  }

  .land .pt-lg-5 {
    padding-top: 3rem !important;
  }

  .land .pe-lg-0 {
    padding-right: 0 !important;
  }

  .land .pe-lg-1 {
    padding-right: 0.25rem !important;
  }

  .land .pe-lg-2 {
    padding-right: 0.5rem !important;
  }

  .land .pe-lg-3 {
    padding-right: 1rem !important;
  }

  .land .pe-lg-4 {
    padding-right: 1.5rem !important;
  }

  .land .pe-lg-5 {
    padding-right: 3rem !important;
  }

  .land .pb-lg-0 {
    padding-bottom: 0 !important;
  }

  .land .pb-lg-1 {
    padding-bottom: 0.25rem !important;
  }

  .land .pb-lg-2 {
    padding-bottom: 0.5rem !important;
  }

  .land .pb-lg-3 {
    padding-bottom: 1rem !important;
  }

  .land .pb-lg-4 {
    padding-bottom: 1.5rem !important;
  }

  .land .pb-lg-5 {
    padding-bottom: 3rem !important;
  }

  .land .ps-lg-0 {
    padding-left: 0 !important;
  }

  .land .ps-lg-1 {
    padding-left: 0.25rem !important;
  }

  .land .ps-lg-2 {
    padding-left: 0.5rem !important;
  }

  .land .ps-lg-3 {
    padding-left: 1rem !important;
  }

  .land .ps-lg-4 {
    padding-left: 1.5rem !important;
  }

  .land .ps-lg-5 {
    padding-left: 3rem !important;
  }

  .land .text-lg-start {
    text-align: left !important;
  }

  .land .text-lg-end {
    text-align: right !important;
  }

  .land .text-lg-center {
    text-align: center !important;
  }
}

@media (min-width: 1200px) {
  .land .d-xl-inline {
    display: inline !important;
  }

  .land .d-xl-inline-block {
    display: inline-block !important;
  }

  .land .d-xl-block {
    display: block !important;
  }

  .land .d-xl-grid {
    display: grid !important;
  }

  .land .d-xl-table {
    display: table !important;
  }

  .land .d-xl-table-row {
    display: table-row !important;
  }

  .land .d-xl-table-cell {
    display: table-cell !important;
  }

  .land .d-xl-flex {
    display: flex !important;
  }

  .land .d-xl-inline-flex {
    display: inline-flex !important;
  }

  .land .d-xl-none {
    display: none !important;
  }

  .land .flex-xl-fill {
    flex: 1 1 auto !important;
  }

  .land .flex-xl-row {
    flex-direction: row !important;
  }

  .land .flex-xl-column {
    flex-direction: column !important;
  }

  .land .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .land .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .land .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }

  .land .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }

  .land .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .land .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .land .flex-xl-wrap {
    flex-wrap: wrap !important;
  }

  .land .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }

  .land .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .land .gap-xl-0 {
    grid-gap: 0 !important;
    gap: 0 !important;
  }

  .land .gap-xl-1 {
    grid-gap: 0.25rem !important;
    gap: 0.25rem !important;
  }

  .land .gap-xl-2 {
    grid-gap: 0.5rem !important;
    gap: 0.5rem !important;
  }

  .land .gap-xl-3 {
    grid-gap: 1rem !important;
    gap: 1rem !important;
  }

  .land .gap-xl-4 {
    grid-gap: 1.5rem !important;
    gap: 1.5rem !important;
  }

  .land .gap-xl-5 {
    grid-gap: 3rem !important;
    gap: 3rem !important;
  }

  .land .justify-content-xl-start {
    justify-content: flex-start !important;
  }

  .land .justify-content-xl-end {
    justify-content: flex-end !important;
  }

  .land .justify-content-xl-center {
    justify-content: center !important;
  }

  .land .justify-content-xl-between {
    justify-content: space-between !important;
  }

  .land .justify-content-xl-around {
    justify-content: space-around !important;
  }

  .land .justify-content-xl-evenly {
    justify-content: space-evenly !important;
  }

  .land .align-items-xl-start {
    align-items: flex-start !important;
  }

  .land .align-items-xl-end {
    align-items: flex-end !important;
  }

  .land .align-items-xl-center {
    align-items: center !important;
  }

  .land .align-items-xl-baseline {
    align-items: baseline !important;
  }

  .land .align-items-xl-stretch {
    align-items: stretch !important;
  }

  .land .align-content-xl-start {
    align-content: flex-start !important;
  }

  .land .align-content-xl-end {
    align-content: flex-end !important;
  }

  .land .align-content-xl-center {
    align-content: center !important;
  }

  .land .align-content-xl-between {
    align-content: space-between !important;
  }

  .land .align-content-xl-around {
    align-content: space-around !important;
  }

  .land .align-content-xl-stretch {
    align-content: stretch !important;
  }

  .land .align-self-xl-auto {
    align-self: auto !important;
  }

  .land .align-self-xl-start {
    align-self: flex-start !important;
  }

  .land .align-self-xl-end {
    align-self: flex-end !important;
  }

  .land .align-self-xl-center {
    align-self: center !important;
  }

  .land .align-self-xl-baseline {
    align-self: baseline !important;
  }

  .land .align-self-xl-stretch {
    align-self: stretch !important;
  }

  .land .order-xl-first {
    order: -1 !important;
  }

  .land .order-xl-0 {
    order: 0 !important;
  }

  .land .order-xl-1 {
    order: 1 !important;
  }

  .land .order-xl-2 {
    order: 2 !important;
  }

  .land .order-xl-3 {
    order: 3 !important;
  }

  .land .order-xl-4 {
    order: 4 !important;
  }

  .land .order-xl-5 {
    order: 5 !important;
  }

  .land .order-xl-last {
    order: 6 !important;
  }

  .land .m-xl-0 {
    margin: 0 !important;
  }

  .land .m-xl-1 {
    margin: 0.25rem !important;
  }

  .land .m-xl-2 {
    margin: 0.5rem !important;
  }

  .land .m-xl-3 {
    margin: 1rem !important;
  }

  .land .m-xl-4 {
    margin: 1.5rem !important;
  }

  .land .m-xl-5 {
    margin: 3rem !important;
  }

  .land .m-xl-auto {
    margin: auto !important;
  }

  .land .mx-xl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .land .mx-xl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .land .mx-xl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .land .mx-xl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .land .mx-xl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .land .mx-xl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .land .mx-xl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .land .my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .land .my-xl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .land .my-xl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .land .my-xl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .land .my-xl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .land .my-xl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .land .my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .land .mt-xl-0 {
    margin-top: 0 !important;
  }

  .land .mt-xl-1 {
    margin-top: 0.25rem !important;
  }

  .land .mt-xl-2 {
    margin-top: 0.5rem !important;
  }

  .land .mt-xl-3 {
    margin-top: 1rem !important;
  }

  .land .mt-xl-4 {
    margin-top: 1.5rem !important;
  }

  .land .mt-xl-5 {
    margin-top: 3rem !important;
  }

  .land .mt-xl-auto {
    margin-top: auto !important;
  }

  .land .me-xl-0 {
    margin-right: 0 !important;
  }

  .land .me-xl-1 {
    margin-right: 0.25rem !important;
  }

  .land .me-xl-2 {
    margin-right: 0.5rem !important;
  }

  .land .me-xl-3 {
    margin-right: 1rem !important;
  }

  .land .me-xl-4 {
    margin-right: 1.5rem !important;
  }

  .land .me-xl-5 {
    margin-right: 3rem !important;
  }

  .land .me-xl-auto {
    margin-right: auto !important;
  }

  .land .mb-xl-0 {
    margin-bottom: 0 !important;
  }

  .land .mb-xl-1 {
    margin-bottom: 0.25rem !important;
  }

  .land .mb-xl-2 {
    margin-bottom: 0.5rem !important;
  }

  .land .mb-xl-3 {
    margin-bottom: 1rem !important;
  }

  .land .mb-xl-4 {
    margin-bottom: 1.5rem !important;
  }

  .land .mb-xl-5 {
    margin-bottom: 3rem !important;
  }

  .land .mb-xl-auto {
    margin-bottom: auto !important;
  }

  .land .ms-xl-0 {
    margin-left: 0 !important;
  }

  .land .ms-xl-1 {
    margin-left: 0.25rem !important;
  }

  .land .ms-xl-2 {
    margin-left: 0.5rem !important;
  }

  .land .ms-xl-3 {
    margin-left: 1rem !important;
  }

  .land .ms-xl-4 {
    margin-left: 1.5rem !important;
  }

  .land .ms-xl-5 {
    margin-left: 3rem !important;
  }

  .land .ms-xl-auto {
    margin-left: auto !important;
  }

  .land .p-xl-0 {
    padding: 0 !important;
  }

  .land .p-xl-1 {
    padding: 0.25rem !important;
  }

  .land .p-xl-2 {
    padding: 0.5rem !important;
  }

  .land .p-xl-3 {
    padding: 1rem !important;
  }

  .land .p-xl-4 {
    padding: 1.5rem !important;
  }

  .land .p-xl-5 {
    padding: 3rem !important;
  }

  .land .px-xl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .land .px-xl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .land .px-xl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .land .px-xl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .land .px-xl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .land .px-xl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .land .py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .land .py-xl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .land .py-xl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .land .py-xl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .land .py-xl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .land .py-xl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .land .pt-xl-0 {
    padding-top: 0 !important;
  }

  .land .pt-xl-1 {
    padding-top: 0.25rem !important;
  }

  .land .pt-xl-2 {
    padding-top: 0.5rem !important;
  }

  .land .pt-xl-3 {
    padding-top: 1rem !important;
  }

  .land .pt-xl-4 {
    padding-top: 1.5rem !important;
  }

  .land .pt-xl-5 {
    padding-top: 3rem !important;
  }

  .land .pe-xl-0 {
    padding-right: 0 !important;
  }

  .land .pe-xl-1 {
    padding-right: 0.25rem !important;
  }

  .land .pe-xl-2 {
    padding-right: 0.5rem !important;
  }

  .land .pe-xl-3 {
    padding-right: 1rem !important;
  }

  .land .pe-xl-4 {
    padding-right: 1.5rem !important;
  }

  .land .pe-xl-5 {
    padding-right: 3rem !important;
  }

  .land .pb-xl-0 {
    padding-bottom: 0 !important;
  }

  .land .pb-xl-1 {
    padding-bottom: 0.25rem !important;
  }

  .land .pb-xl-2 {
    padding-bottom: 0.5rem !important;
  }

  .land .pb-xl-3 {
    padding-bottom: 1rem !important;
  }

  .land .pb-xl-4 {
    padding-bottom: 1.5rem !important;
  }

  .land .pb-xl-5 {
    padding-bottom: 3rem !important;
  }

  .land .ps-xl-0 {
    padding-left: 0 !important;
  }

  .land .ps-xl-1 {
    padding-left: 0.25rem !important;
  }

  .land .ps-xl-2 {
    padding-left: 0.5rem !important;
  }

  .land .ps-xl-3 {
    padding-left: 1rem !important;
  }

  .land .ps-xl-4 {
    padding-left: 1.5rem !important;
  }

  .land .ps-xl-5 {
    padding-left: 3rem !important;
  }

  .land .ps-xl-6 {
    padding-left: 6rem !important;
  }

  .land .text-xl-start {
    text-align: left !important;
  }

  .land .text-xl-end {
    text-align: right !important;
  }

  .land .text-xl-center {
    text-align: center !important;
  }
}

@media (min-width: 1400px) {
  .land .d-xxl-inline {
    display: inline !important;
  }

  .land .d-xxl-inline-block {
    display: inline-block !important;
  }

  .land .d-xxl-block {
    display: block !important;
  }

  .land .d-xxl-grid {
    display: grid !important;
  }

  .land .d-xxl-table {
    display: table !important;
  }

  .land .d-xxl-table-row {
    display: table-row !important;
  }

  .land .d-xxl-table-cell {
    display: table-cell !important;
  }

  .land .d-xxl-flex {
    display: flex !important;
  }

  .land .d-xxl-inline-flex {
    display: inline-flex !important;
  }

  .land .d-xxl-none {
    display: none !important;
  }

  .land .flex-xxl-fill {
    flex: 1 1 auto !important;
  }

  .land .flex-xxl-row {
    flex-direction: row !important;
  }

  .land .flex-xxl-column {
    flex-direction: column !important;
  }

  .land .flex-xxl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .land .flex-xxl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .land .flex-xxl-grow-0 {
    flex-grow: 0 !important;
  }

  .land .flex-xxl-grow-1 {
    flex-grow: 1 !important;
  }

  .land .flex-xxl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .land .flex-xxl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .land .flex-xxl-wrap {
    flex-wrap: wrap !important;
  }

  .land .flex-xxl-nowrap {
    flex-wrap: nowrap !important;
  }

  .land .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .land .gap-xxl-0 {
    grid-gap: 0 !important;
    gap: 0 !important;
  }

  .land .gap-xxl-1 {
    grid-gap: 0.25rem !important;
    gap: 0.25rem !important;
  }

  .land .gap-xxl-2 {
    grid-gap: 0.5rem !important;
    gap: 0.5rem !important;
  }

  .land .gap-xxl-3 {
    grid-gap: 1rem !important;
    gap: 1rem !important;
  }

  .land .gap-xxl-4 {
    grid-gap: 1.5rem !important;
    gap: 1.5rem !important;
  }

  .land .gap-xxl-5 {
    grid-gap: 3rem !important;
    gap: 3rem !important;
  }

  .land .justify-content-xxl-start {
    justify-content: flex-start !important;
  }

  .land .justify-content-xxl-end {
    justify-content: flex-end !important;
  }

  .land .justify-content-xxl-center {
    justify-content: center !important;
  }

  .land .justify-content-xxl-between {
    justify-content: space-between !important;
  }

  .land .justify-content-xxl-around {
    justify-content: space-around !important;
  }

  .land .justify-content-xxl-evenly {
    justify-content: space-evenly !important;
  }

  .land .align-items-xxl-start {
    align-items: flex-start !important;
  }

  .land .align-items-xxl-end {
    align-items: flex-end !important;
  }

  .land .align-items-xxl-center {
    align-items: center !important;
  }

  .land .align-items-xxl-baseline {
    align-items: baseline !important;
  }

  .land .align-items-xxl-stretch {
    align-items: stretch !important;
  }

  .land .align-content-xxl-start {
    align-content: flex-start !important;
  }

  .land .align-content-xxl-end {
    align-content: flex-end !important;
  }

  .land .align-content-xxl-center {
    align-content: center !important;
  }

  .land .align-content-xxl-between {
    align-content: space-between !important;
  }

  .land .align-content-xxl-around {
    align-content: space-around !important;
  }

  .land .align-content-xxl-stretch {
    align-content: stretch !important;
  }

  .land .align-self-xxl-auto {
    align-self: auto !important;
  }

  .land .align-self-xxl-start {
    align-self: flex-start !important;
  }

  .land .align-self-xxl-end {
    align-self: flex-end !important;
  }

  .land .align-self-xxl-center {
    align-self: center !important;
  }

  .land .align-self-xxl-baseline {
    align-self: baseline !important;
  }

  .land .align-self-xxl-stretch {
    align-self: stretch !important;
  }

  .land .order-xxl-first {
    order: -1 !important;
  }

  .land .order-xxl-0 {
    order: 0 !important;
  }

  .land .order-xxl-1 {
    order: 1 !important;
  }

  .land .order-xxl-2 {
    order: 2 !important;
  }

  .land .order-xxl-3 {
    order: 3 !important;
  }

  .land .order-xxl-4 {
    order: 4 !important;
  }

  .land .order-xxl-5 {
    order: 5 !important;
  }

  .land .order-xxl-last {
    order: 6 !important;
  }

  .land .m-xxl-0 {
    margin: 0 !important;
  }

  .land .m-xxl-1 {
    margin: 0.25rem !important;
  }

  .land .m-xxl-2 {
    margin: 0.5rem !important;
  }

  .land .m-xxl-3 {
    margin: 1rem !important;
  }

  .land .m-xxl-4 {
    margin: 1.5rem !important;
  }

  .land .m-xxl-5 {
    margin: 3rem !important;
  }

  .land .m-xxl-auto {
    margin: auto !important;
  }

  .land .mx-xxl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .land .mx-xxl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .land .mx-xxl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .land .mx-xxl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .land .mx-xxl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .land .mx-xxl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .land .mx-xxl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .land .my-xxl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .land .my-xxl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .land .my-xxl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .land .my-xxl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .land .my-xxl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .land .my-xxl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .land .my-xxl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .land .mt-xxl-0 {
    margin-top: 0 !important;
  }

  .land .mt-xxl-1 {
    margin-top: 0.25rem !important;
  }

  .land .mt-xxl-2 {
    margin-top: 0.5rem !important;
  }

  .land .mt-xxl-3 {
    margin-top: 1rem !important;
  }

  .land .mt-xxl-4 {
    margin-top: 1.5rem !important;
  }

  .land .mt-xxl-5 {
    margin-top: 3rem !important;
  }

  .land .mt-xxl-auto {
    margin-top: auto !important;
  }

  .land .me-xxl-0 {
    margin-right: 0 !important;
  }

  .land .me-xxl-1 {
    margin-right: 0.25rem !important;
  }

  .land .me-xxl-2 {
    margin-right: 0.5rem !important;
  }

  .land .me-xxl-3 {
    margin-right: 1rem !important;
  }

  .land .me-xxl-4 {
    margin-right: 1.5rem !important;
  }

  .land .me-xxl-5 {
    margin-right: 3rem !important;
  }

  .land .me-xxl-auto {
    margin-right: auto !important;
  }

  .land .mb-xxl-0 {
    margin-bottom: 0 !important;
  }

  .land .mb-xxl-1 {
    margin-bottom: 0.25rem !important;
  }

  .land .mb-xxl-2 {
    margin-bottom: 0.5rem !important;
  }

  .land .mb-xxl-3 {
    margin-bottom: 1rem !important;
  }

  .land .mb-xxl-4 {
    margin-bottom: 1.5rem !important;
  }

  .land .mb-xxl-5 {
    margin-bottom: 3rem !important;
  }

  .land .mb-xxl-auto {
    margin-bottom: auto !important;
  }

  .land .ms-xxl-0 {
    margin-left: 0 !important;
  }

  .land .ms-xxl-1 {
    margin-left: 0.25rem !important;
  }

  .land .ms-xxl-2 {
    margin-left: 0.5rem !important;
  }

  .land .ms-xxl-3 {
    margin-left: 1rem !important;
  }

  .land .ms-xxl-4 {
    margin-left: 1.5rem !important;
  }

  .land .ms-xxl-5 {
    margin-left: 3rem !important;
  }

  .land .ms-xxl-auto {
    margin-left: auto !important;
  }

  .land .p-xxl-0 {
    padding: 0 !important;
  }

  .land .p-xxl-1 {
    padding: 0.25rem !important;
  }

  .land .p-xxl-2 {
    padding: 0.5rem !important;
  }

  .land .p-xxl-3 {
    padding: 1rem !important;
  }

  .land .p-xxl-4 {
    padding: 1.5rem !important;
  }

  .land .p-xxl-5 {
    padding: 3rem !important;
  }

  .land .px-xxl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .land .px-xxl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .land .px-xxl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .land .px-xxl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .land .px-xxl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .land .px-xxl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .land .py-xxl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .land .py-xxl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .land .py-xxl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .land .py-xxl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .land .py-xxl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .land .py-xxl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .land .pt-xxl-0 {
    padding-top: 0 !important;
  }

  .land .pt-xxl-1 {
    padding-top: 0.25rem !important;
  }

  .land .pt-xxl-2 {
    padding-top: 0.5rem !important;
  }

  .land .pt-xxl-3 {
    padding-top: 1rem !important;
  }

  .land .pt-xxl-4 {
    padding-top: 1.5rem !important;
  }

  .land .pt-xxl-5 {
    padding-top: 3rem !important;
  }

  .land .pe-xxl-0 {
    padding-right: 0 !important;
  }

  .land .pe-xxl-1 {
    padding-right: 0.25rem !important;
  }

  .land .pe-xxl-2 {
    padding-right: 0.5rem !important;
  }

  .land .pe-xxl-3 {
    padding-right: 1rem !important;
  }

  .land .pe-xxl-4 {
    padding-right: 1.5rem !important;
  }

  .land .pe-xxl-5 {
    padding-right: 3rem !important;
  }

  .land .pb-xxl-0 {
    padding-bottom: 0 !important;
  }

  .land .pb-xxl-1 {
    padding-bottom: 0.25rem !important;
  }

  .land .pb-xxl-2 {
    padding-bottom: 0.5rem !important;
  }

  .land .pb-xxl-3 {
    padding-bottom: 1rem !important;
  }

  .land .pb-xxl-4 {
    padding-bottom: 1.5rem !important;
  }

  .land .pb-xxl-5 {
    padding-bottom: 3rem !important;
  }

  .land .ps-xxl-0 {
    padding-left: 0 !important;
  }

  .land .ps-xxl-1 {
    padding-left: 0.25rem !important;
  }

  .land .ps-xxl-2 {
    padding-left: 0.5rem !important;
  }

  .land .ps-xxl-3 {
    padding-left: 1rem !important;
  }

  .land .ps-xxl-4 {
    padding-left: 1.5rem !important;
  }

  .land .ps-xxl-5 {
    padding-left: 3rem !important;
  }
  .land .ps-xxl-10 {
    padding-left: 10rem !important;
  }

  .land .ps-xxl-12 {
    padding-left: 12rem !important;
  }

  .land .text-xxl-start {
    text-align: left !important;
  }

  .land .text-xxl-end {
    text-align: right !important;
  }

  .land .text-xxl-center {
    text-align: center !important;
  }
}

@media print {
  .land .d-print-inline {
    display: inline !important;
  }

  .land .d-print-inline-block {
    display: inline-block !important;
  }

  .land .d-print-block {
    display: block !important;
  }

  .land .d-print-grid {
    display: grid !important;
  }

  .land .d-print-table {
    display: table !important;
  }

  .land .d-print-table-row {
    display: table-row !important;
  }

  .land .d-print-table-cell {
    display: table-cell !important;
  }

  .land .d-print-flex {
    display: flex !important;
  }

  .land .d-print-inline-flex {
    display: inline-flex !important;
  }

  .land .d-print-none {
    display: none !important;
  }
}

html {
  scroll-padding-top: 4.5rem;
}

body {
  position: relative;
  overflow-x: hidden;
}

p {
  line-height: 1.75;
}

section {
  padding: 1rem 0rem;
}

.page-section-land {
  margin: 1rem 0;
}

#mainNav {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  background: #ffffff;
  background: var(--body-bg-white);
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
    rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
}

#mainNav .land .navbar-toggler {
  padding: 0.75rem;
  text-transform: uppercase;
  font-weight: 700;
}

#mainNav .land .navbar-brand {
  letter-spacing: 0.0625em;
  text-transform: uppercase;
  width: auto;
  max-width: 75%;
}

.land .navbar-brand img {
  height: auto;
  width: 100%;
  max-height: 60px;
  max-width: 210px;
}

#mainNav .land .navbar-nav .land .nav-item .land .nav-link {
  font-size: 0.9rem;
  color: #1d3a60;
  color: var(--color-dark-blue);
  letter-spacing: 0.0625em;
  font-weight: 500;
}

#mainNav .land .navbar-nav .land .nav-item .land .nav-link.active,
#mainNav .land .navbar-nav .land .nav-item .land .nav-link:hover {
  color: #5d9bf8;
  color: var(--color-hover-a);
}

@media (min-width: 576px) {
  .land .block-projects a {
    font-size: 1.5rem;
  }
}

@media (min-width: 992px) {
  .land .block-projects a {
    font-size: 1.5rem;
  }

  #mainNav {
    border: none;
    transition: padding-top 0.3s ease-in-out, padding-bottom 0.3s ease-in-out;
  }

  #mainNav .land .navbar-brand {
    transition: font-size 0.3s ease-in-out;
    min-width: auto;
  }
}

/*
---------------------------------------------
Section
---------------------------------------------
*/

.land .btn-link-mob {
  position: -webkit-sticky;
  position: sticky;
  margin-right: 11%;
}

.land .logo-mob-land {
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.land .logo-mob-land img {
  height: auto;
  width: 100%;
  max-height: 50px;
  max-width: 183px;
}

.land .solid,
.land .solid-2 {
  width: 100%;
  border-radius: 16px;
}

.land .text-wrapper-land {
  padding: 1rem 0;
}

.section-1-land {
  height: 770px;
  background-image: url(/static/media/section1-bg-mob.2a93c347.png);
  background-position: right center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 0px;
  margin-top: 5rem;
  position: relative;
  flex-direction: column-reverse;
}

.section-2-land,
.section-5-land {
  width: 100%;
  height: auto;
  min-height: 750px;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  flex-direction: column-reverse;
}

.section-2-land {
  min-height: auto;
}

.section-5-land {
  min-height: 600px !important;
}

.section-2-land img,
.section-5-land img {
  width: 100%;
  height: auto;
}

.h2-land {
  padding: 1rem 0rem 0rem 0rem;
  font-size: 33px;
  line-height: 40px;
  letter-spacing: 0em;
}

.section-2-land p,
.section-3-land p,
.section-5-land p {
  padding: 0rem 1rem 0rem 0rem;
}

.remark {
  text-align: left;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0em;
}

.section-3-land {
  width: 100%;
  height: 460px;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-image: url(/static/media/section3-bg.374aab49.png);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  border-radius: 20px;
}

.section-3-land img {
  height: 70%;
  width: auto;
}

.section-3-land-top {
  height: 45%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

.section-3-land-bottom {
  height: 55%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.section-4-land {
  position: relative;
  width: 100%;
  height: 1000px;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.section-4-land-top {
  height: 50%;
  background-image: url(/static/media/section4-bg-mob.bd0c1542.png);
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  border-radius: 16px;
}

.section-4-land-bottom {
  height: 50%;
  background: #f1f5ff;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
}

.section-4-land img {
  max-width: 90%;
}

.section-6-land {
  height: auto;
  /* background-color: #f1f5ff; */
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
  border-radius: 16px;
  /* background: linear-gradient(
    180.13deg,
    #dfeafe 0.17%,
    #e2edff 48.04%,
    #dce8fc 99.95%
  ); */
  /* background-image: url(../assets/img/background/section6-bg-mob.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: right right; */
}

.section-6-land h2 {
  width: 80%;
  padding: 0;
  margin-top: 0 !important;
}

/* .land .section-6-land-top {
  width: 100%;
  height: 360px;
  
} */

.section-6-land-card {
  /* height: 200px; */
  text-align: center;
  margin: 0 0 1rem 0;
  margin-bottom: 24px;
}

.section-6-land-card img {
  height: 80px;
  width: auto;
}

.section-6-land-card-top {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.section-6-land-card-bottom {
  height: auto;
  padding: 0 1rem;
}

.section-7-land {
  height: 1270px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}
.blue-line-1,
.blue-line-2 {
  visibility: hidden;
  display: none;
}

.achievements-card {
  width: 85%;
  /* height: 155px; */
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
  /* margin: 0.5rem 0; */
  margin-bottom: 24px;
}

.achievements-card:first-child {
  margin-top: 30px;
}

@media (min-width: 465px) {
  .land .achievements-card:first-child {
    margin-top: 0px;
  }
}

.section-7-land img {
  height: 80px;
  width: auto;
}

.block-projects {
  padding: 0.2rem 1.5rem 0.2rem 2rem;
}

.block-projects a {
  font-weight: 400;
  font-size: 1.7rem;
  letter-spacing: 8px;
  transition: all 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;
}

.block-projects img {
  width: 87%;
  height: auto;
}

.section-8-land {
  width: 100%;
  height: auto;
}

.section-9-land {
  height: 1000px;
  background-color: #f1f5ff;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
  border-radius: 16px;
  overflow: hidden;
}

.section-9-land-top {
  width: 100%;
  height: 330px;
  background-image: url(/static/media/section9-bg-mob.4665e668.png);
  background-repeat: no-repeat;
  background-size: contain;
  border-radius: 16px;
}

.section-9-land-card {
  height: 270px;
  text-align: center;
  margin: 1rem 0;
}

.section-9-land-card img {
  height: 80%;
  width: auto;
}

.section-9-land-card-top {
  height: 40%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.section-9-land-card-bottom {
  height: 50%;
  padding: 0 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.section-9-land h2 {
  width: 100%;
}

.section-10-land {
  height: 770px;
  background-image: url(/static/media/section10-bg-mob.c445a581.png);
  background-position: right center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 0px;
  margin-top: 5rem;
  position: relative;
  flex-direction: column-reverse;
}

.card-land-text {
  font-size: 17px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0px;
  margin: 0.5rem 0;
  color: #1d3a60;
  color: var(--color-dark-blue);
}

.line {
  position: absolute;
  top: 50%;
}

.left-content-land {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: column;
  text-align: center;
  height: 40%;
  padding: 0 1rem 2.5rem 1rem;
}

.right-content-land {
  height: 60%;
  position: relative;
  padding-top: 56.25%;
}

.right-content-land img {
  position: absolute;
  height: auto;
  top: -14%;
  left: 17%;
  width: 64%;
}

.display-full {
  visibility: hidden !important;
  display: none;
}

.text-title-land {
  max-width: 90%;
}

.text-subtitle-land {
  font-size: 17px;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0em;
  margin-bottom: 1.5rem;
  max-width: 100%;
}

.applications-btn-wrapper {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
}
.applications-btn-wrapper a:first-child {
  margin-right: 14px;
}

@media (min-width: 400px) {
  .land .left-content-land {
    padding: 0 3rem 2.5rem 3rem;
  }
  .land .btn-link-mob {
    margin-right: 16%;
  }
  .land .right-content-land img {
    left: 20%;
    width: 60%;
  }
  .land .applications-btn-wrapper a:first-child {
    margin-right: 18px;
  }
}

@media (min-width: 465px) {
  .land .right-content-land img {
    left: 22%;
    width: 55%;
  }

  .land .solid {
    height: 870px;
  }

  .land .solid-2 {
    height: 820px;
  }
  .land .btn-link-mob {
    margin-right: 22%;
  }
  .land .left-content-land {
    padding: 0 5rem 2.5rem 5rem;
  }

  .land .section-4-land {
    height: 480px;
  }

  .land .section-4-land-top {
    display: none;
  }

  .land .section-4-land img {
    max-width: 70%;
  }
  .land .section-4-land-bottom {
    height: 100%;
    justify-content: flex-start;
  }

  /* .land .section-6-land-top {
    height: 426px;
  } */
}

@media (min-width: 576px) {
  .land .section-9-land-top {
    background-size: cover;
  }

  .land .right-content-land img {
    left: 25%;
    width: 50%;
  }

  .land .section-1-land,
  .land .section-10-land {
    height: 920px;
  }

  .land .left-content-land {
    padding: 0 5rem 2.5rem 5rem;
    height: 30%;
  }
  .land .btn-link-mob {
    margin-right: 27%;
  }

  .land .right-content-land {
    height: 70%;
  }

  .land .section-6-land-top,
  .land .section-9-land-top,
  .land .section-9-land-card-top {
    display: none;
  }

  .land .section-6-land {
    /* height: 600px; */
    /* background-color: #f1f5ff; */
    display: flex;
    flex-direction: revert;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    text-align: center;
    border-radius: 16px;
    /* background-image: none; */
  }

  .land .achievements-card {
    width: 50%;
  }
  .land .section-7-land {
    flex-direction: row;
    flex-wrap: wrap;
    height: 900px;
  }

  .land .section-9-land {
    height: 650px;
  }
}

@media (min-width: 660px) {
  .land .right-content-land img {
    width: 35%;
    left: 32%;
  }
  .land .btn-link-mob {
    margin-right: 31%;
  }
  .land .left-content-land {
    padding: 0 8rem 2.5rem 8rem;
  }
  .land .solid {
    height: 870px;
  }

  .land .solid-2 {
    height: 820px;
  }

  .land .text-title-land {
    max-width: 75%;
    margin-bottom: 2rem;
    line-height: 50px;
  }
  .land .text-subtitle-land {
    max-width: 95%;
    line-height: 32px;
  }
}

@media (min-width: 768px) {
  .land .block-projects {
    padding: 1rem 0;
  }

  .land .left-content-land {
    padding: 0 7rem 3.5rem 7rem;
  }
  .land .btn-link-mob {
    margin-right: 33%;
  }
  .land .solid,
  .land .solid-2 {
    height: 920px;
  }

  .land .text-title-land {
    max-width: 70%;
  }

  .land .text-subtitle-land {
    max-width: 75%;
    line-height: 36px;
  }

  .land .applications-btn-wrapper {
    width: 60%;
  }
  .land .section-4-land img {
    max-width: 55%;
  }

  .land .section-2-land img,
  .land .section-5-land img {
    width: 60%;
    height: auto;
  }
}

@media (min-width: 820px) {
  .land .text-wrapper-land {
    padding: 1rem 0 0 2rem;
  }

  .land .right-content-land img {
    width: 28%;
    left: 37%;
    top: -12%;
  }
  .land .text-title-land {
    max-width: 57%;
  }

  .land .text-subtitle-land {
    max-width: 65%;
    line-height: 36px;
  }

  .land .section-4-land {
    height: 500px;
  }
}

@media (min-width: 992px) {
  .land .section-9-land h2 {
    width: 50%;
  }
  .land .l-h-50 {
    line-height: 50px !important;
  }
  .land .btn-land {
    width: 140px;
    height: 46px;
    display: block !important;
  }

  .land .display-mobile {
    display: none !important;
  }

  .land .display-full {
    visibility: visible !important;
    display: block !important;
  }

  .land .left-content-land {
    align-items: flex-start;
    justify-content: center;
    text-align: left;
    height: 100%;
    padding: 0 0rem 0rem 5rem;
  }
  .land .right-content-land {
    height: 100%;
  }

  .land .right-content-land img {
    width: 70%;
    top: -10%;
    left: 5%;
  }

  .land .section-1-land {
    background-position: inherit;
    background-image: url(/static/media/section1-bg.f5a4b47a.png);
  }

  .land .section-10-land {
    background-position: inherit;
    background-image: url(/static/media/section10-bg.b1adcdc2.png);
  }

  .land .solid {
    border-radius: 65px;
    height: 570px;
    margin-top: 9rem;
  }
  .land .solid-2 {
    border-radius: 65px;
    height: 590px;
  }

  .land .text-title-land {
    font-size: 37px;
    line-height: 60px;
    text-align: left;
    max-width: 95%;
  }

  .land .text-subtitle-land {
    font-size: 17px;
    max-width: 95%;
  }

  .land .applications-btn-wrapper {
    justify-content: flex-start;
    width: 100%;
  }

  .land .applications-btn-wrapper a {
    margin-right: 1rem;
  }

  .land .section-2-land,
  .land .section-5-land {
    height: auto;
    min-height: 550px;
    text-align: left;
    flex-direction: row;
  }

  .land .h2-land {
    font-size: 40px;
    line-height: 50px;
    max-width: 90%;
  }

  .land .section-3-land {
    border-radius: 70px;
  }

  .land .section-4-land {
    background-image: url(/static/media/section4-bg.a5d36186.png);
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    flex-direction: row;
    border-radius: 65px;
    height: 570px;
  }

  .land .section-4-land-top {
    height: 100%;
    background: transparent;
    display: block;
  }

  .land .section-4-land-bottom {
    height: 100%;
    background: transparent;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .land .section-4-land img {
    max-width: 90%;
  }

  .land .section-2-land img,
  .land .section-5-land img {
    width: 100%;
    height: auto;
  }
  .land .section-6-land {
    border-radius: 65px;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-end;
    height: 700px;
    background-image: url(/static/media/section6-bg.d3cc6869.png);
    background-color: transparent;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    padding-left: 10%;
  }

  .land .section-6-land-card {
    width: 350px;
    height: 240px;
  }

  .land .section-6-land-card-bottom {
    text-align: left;
  }

  .land .section-6-land-card-top {
    justify-content: flex-start;
    padding-left: 1rem;
  }

  .land .section-6-land h2 {
    min-width: 100% !important;
  }

  .land .section-7-land {
    height: 550px;
  }
  .land .achievements-card {
    width: 25%;
    justify-content: flex-start;
    /* height: 270px; */
  }

  .land .blue-line-1 {
    display: block;
    visibility: visible;
    width: 300px;
    height: auto;
    bottom: -19%;
    left: 33px;
  }

  .land .blue-line-2 {
    display: block;
    visibility: visible;
    width: 240px;
    height: auto;
    bottom: -45%;
    left: 11px;
  }

  .land .section-9-land {
    border-radius: 65px;
    height: 730px;
    background-image: url(/static/media/section9-bg.2261a90f.png);
    background-color: transparent;
    background-position: right;
    background-repeat: no-repeat;
    background-size: auto;
  }

  .land .section-9-land-card-bottom {
    justify-content: flex-end;
    height: 80%;
  }
}

@media (min-width: 1200px) {
  .land .solid {
    margin-top: 12rem;
  }

  .land .right-content-land img {
    width: 67%;
    top: -4%;
    left: 5%;
  }

  .land .left-content-land {
    padding: 0 0rem 0rem 8rem;
  }

  .land .text-title-land {
    font-size: 46px;
  }

  .land .text-subtitle-land {
    font-size: 20px;
    max-width: 100%;
    margin-bottom: 2.5rem;
  }

  .land .section-4-land img {
    max-width: 70%;
  }

  .land .section-6-land {
    padding-left: 20%;
  }
}

@media (min-width: 1400px) {
  .land .solid,
  .land .solid-2 {
    height: 650px;
  }

  .land .right-content-land img {
    width: 60%;
    top: 1%;
    left: 0%;
  }

  .land .text-title-land {
    max-width: 88%;
  }

  .land .text-subtitle-land {
    max-width: 85%;
  }
}

/*
---------------------------------------------
Footer
---------------------------------------------
*/

.footer-land {
  text-align: center;
  font-size: 0.9rem;
}

@media (max-width: 370px) {
  .land .navbar-brand {
    font-size: 1rem;
  }
}

/*
---------------------------------------------
Carousel
---------------------------------------------
*/

.owl-carousel .land .owl-nav button.owl-prev,
.owl-carousel .land .owl-nav button.owl-next,
.owl-carousel button.owl-dot {
  width: 9px;
  height: 9px;
  margin: 0 0.5rem;
}

.owl-carousel .land .owl-nav button.owl-prev img,
.owl-carousel .land .owl-nav button.owl-next img,
.owl-carousel button.owl-dot img {
  width: 100%;
  height: auto;
}

@media (min-width: 992px) {
  .land .team .land .owl-nav .land .owl-next {
    right: 10%;
    top: 20%;
  }

  .land .team .land .owl-nav .land .owl-prev {
    left: 10%;
    top: 20%;
  }
}

.team {
  width: auto;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}

.card-land {
  /* width: 280px;
  height: 300px; */
  margin: 0rem 1rem;
  transition: all 0.3s;
}

.card-land-body {
  width: 100%;
  height: auto;
}

.team-carousel {
  /* position: relative; */
  /* margin-top: 30px; */
  top: 20%;
  right: 10%;
}

.team-carousel .land .owl-stage-outer {
  padding: 30px 0;
}
.team-carousel .land .owl-nav {
  display: none;
}
.team-carousel .land .owl-dots {
  text-align: center;
}
.team-carousel .land .owl-dots span {
  position: relative;
  width: 6px !important;
  height: 6px !important;
  background-color: #ddd;
  border-radius: 50%;
  margin: 0px 6px;
  display: block;
  border: none;
}

.team-carousel .land .owl-dots .land .active {
  box-shadow: none;
}
.team-carousel .land .owl-dots .land .active span {
  background-color: #5d9bf8 !important;
  background-color: var(--color-second-blue) !important;
  box-shadow: none;
  height: 6px;
  width: 6px;
  /* margin-bottom: -1px; */
}

.team .land .owl-dots .land .owl-dot {
  width: 6px;
  height: 6px;
  background-color: #ddd;
  border-radius: 50%;
  margin: 0px 6px;
}

/* .land .team .land .owl-dots .land .active {
  background-color: var(--color-second-blue) !important;
} */

.team-carousel .land .card-land {
  margin: 0 20px;
  padding: 0 10px;
  border: 0;
}

.team-carousel .land .active {
  opacity: 0.5;
  transition: all 0.3s;
}

.team-carousel .land .center {
  opacity: 1;
}

@media (min-width: 767px) {
  .land .team-carousel {
    margin-top: 20px;
  }
}
.owl-carousel .land .owl-nav button.owl-next,
.owl-carousel .land .owl-nav button.owl-prev {
  outline: 0;
}
.owl-carousel button.owl-dot {
  outline: 0;
}

.carousel-wrapper {
  top: 58%;
  right: 0;
}

@media (min-width: 465px) {
  .land .carousel-wrapper {
    top: 28%;
  }
}
@media (min-width: 767px) {
  .land .carousel-wrapper {
    top: 32%;
  }
}

.owl-carousel {
  margin-top: 40px;
}

@media (min-width: 465px) {
  .land .owl-carousel {
    margin-top: 65%;
  }
}

@media (min-width: 576px) {
  .land .owl-carousel {
    margin-top: 0px;
  }
}

.btn-link-mob,
.land .navbar-collapse .land .btn-land {
  font-weight: 700;
  height: auto;
  padding-top: 10px;
  padding-bottom: 10px;
}

@media (min-width: 576px) {
  .land .navbar-collapse .land .btn-land {
    padding-top: 16px;
    padding-bottom: 16px;
  }
}

.achievements-card .land .card-land-text {
  margin-bottom: 0px;
}

.section-6-land-card-bottom .land .card-land-text {
  margin-bottom: 0;
}

.section-2-land .land .text-wrapper-land {
  text-align: left;
}

@media (min-width: 992px) {
  .land .achievements-card {
    height: auto;
    min-height: 185px;
  }
}

.land .navbar .land .btn-land a {
  color: #ffffff;
  color: var(--color-white);
}
*,
*::before,
*::after {
  padding: 0;
  margin: 0;
  border: 0;
  box-sizing: border-box;
}

a {
  text-decoration: none;
  -webkit-tap-highlight-color: transparent;
  outline: none;
}

ul,
ol,
li {
  list-style: none;
}

img {
  vertical-align: top;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: inherit;
  font-size: inherit;
}

html,
body {
  line-height: 1;
  font-size: 14px;
  background: rgba(59, 18, 59, 0.067);
}

.App {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: #FBFCFF;
  overflow-x: hidden;
}

.hospital-wrapper {
  width: 1.6rem;
  height: 1.6rem;
  display: block;
  left: -0.8rem;
  top: -0.3rem;
  position: relative;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  border-radius: 3rem 3rem 0;
  border: 1px solid #ff0000;
}

.hospital-styles {
  background-color: #ff0000;
  width: 1.5rem;
  height: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 3rem 3rem 0;
  border: 1px solid #ff0000;
}

.hospital-image {
  width: 80%;
  height: 80%;
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
}

.hospital-wrapper:hover {
  width: 1.8rem;
  height: 1.8rem;
}

.hospital-wrapper:hover .hospital-styles {
  width: 1.8rem;
  height: 1.8rem;
  background-color: #dd0000;
  border: 1px solid #dd0000;
}


.slick-dots li button::before {
  color: #fff;
  opacity: 1;
}

.slick-active button::before {
  color: #dd284a !important;
  opacity: 1;
}

.MuiListItem-root:hover {
  cursor: pointer;
}

.watches-slider .slick-slide img {
  /* display: flex;
  justify-content: center;
  align-items: center; */
  display: inline-block;
}

.markercluster-map {
  height: 90vh;
}

.icon .MuiBadge-badge {
  height: 17.5px;
  min-width: 10px;
  width: 17.5px;
}

.page__section {
  max-width: 1800px;
  min-height: 100%;
  margin: 0px auto;
}
.content-footer__logo {
  width: 100%;
  height: 10%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: whitesmoke;
  z-index: 5;
}
.content-footer__misu-logo {
  font-size: 20px;
  line-height: 30px;
  margin-right: 2%;
  font-family: "Dosis", sans-serif;
}
.content-footer__misu-img {
  height: 42px;
  width: 42px;
  margin-right: 2%;
}
.content-footer__copyright {
  font-family: "Gilroy-Regular";
  opacity: 0.7;
  font-size: 12px;
  line-height: 20px;
  text-align: left;
  color: whitesmoke;
}
.downloadApp {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}
.downloadApp ul {
  height: 250px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}
.downloadApp ul img {
  height: 55px;
  width: 190px;
}
.downloadApp__misu-logo {
  font-size: 38px;
  margin-left: 5%;
}
.downloadApp__copyright {
  margin-top: 15%;
}

@charset "UTF-8";

/*
---------------------------------------------
Blocks VUSO
---------------------------------------------
*/

body {
  background-color: #fbfcff;
}

.page-section-vuso {
  font-family: "Manrope", sans-serif;
  font-size: 16px;
  font-weight: 400;
  color: #3d3d3d;
  background: #ffffff;
  border: 1px solid #e4efff;
  border-radius: 32px;
  overflow-x: hidden;
}

.container-vuso {
  width: 100%;
  min-height: 350px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

@media (min-width: 992px) {
  .container-vuso {
    margin-right: auto;
    margin-left: auto;
    max-width: 960px;
    min-height: 410px;
  }
}

@media (min-width: 1200px) {
  .container-vuso {
    max-width: 1140px;
  }
}

@media (min-width: 1400px) {
  .container-vuso {
    max-width: 1320px;
  }
}

.repeat-payment {
  min-height: 325px !important;
}

.section-vuso-text {
  max-width: 90%;
  font-family: "Manrope", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 140%;
  text-align: center;
  color: #69779a;
}

.vuso-client-number,
.activation-checkin {
  font-weight: 600;
}

.activation-checkin {
  font-family: "Manrope";
  font-weight: 500;
  font-size: 20px;
  line-height: 140%;
  color: #1d3a60;
  margin-bottom: 20px;
}

.vuso-client-number {
  color: #1d3a60;
  font-weight: 800;
  font-size: 16px;
  line-height: 140%;
}

.section-vuso-icon {
  width: 72px;
  height: 72px;
}

.section-vuso-icon img {
  width: 100%;
  height: auto;
}

.btn-vuso {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 343px;
  height: 54px;
  background: #ff5e5e;
  color: #ffffff;
  border-radius: 40px;
  border: none;
  text-align: center;
  cursor: pointer;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  font-family: "Manrope", sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 16px;
  line-height: 140%;
}

.btn-vuso a {
  color: #ffffff;
  text-decoration: none;
}

.btn-vuso:hover {
  background: #fbb8b8;
}

.btn-vuso.disabled {
  pointer-events: none;
  opacity: 0.5;
}

/* form */

.verification-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.form-control {
  border: none;
  background-image: none;
  background-color: transparent;
  box-shadow: none;
  text-align: center;
  height: 70px;
  width: 60px;
  border-radius: 12px;
  margin: 0 6px;
  border: 1px solid #e4efff;
  font-size: 28px;
}
form .form-control:last-child {
  margin-right: 0;
}

.form-control:invalid,
.form-control:required {
  outline: none;
  border: 1px solid #ff5e5e;
}

.form-control:focus {
  outline: none;
  border: 1px solid #5d9bf8;
}

.form-control:valid {
  outline: none;
  border: 1px solid #5d9bf8;
}

.form-control:blank,
.form-control:empty {
  outline: none;
  border: 1px solid #e4efff;
}

/*
---------------------------------------------
Navbar Menu
---------------------------------------------
*/

.d-flex {
  display: flex !important;
}

.d-block {
  display: block !important;
}

.d-none {
  display: none !important;
}

.my-3 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.my-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.mt-4 {
  margin-top: 1.5rem !important;
}

.mb-3 {
  margin-bottom: 1rem !important;
}

.ms-auto {
  margin-left: auto !important;
}

.fixed-top {
  position: fixed !important;
  top: 0;
  right: 0;
  left: 0;
}

/* ol,
ul {
  padding-left: 2rem;
}

ol,
ul {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
} */

.container {
  width: 100%;
  padding-right: 0.75rem;
  padding-right: var(--bs-gutter-x, 0.75rem);
  padding-left: 0.75rem;
  padding-left: var(--bs-gutter-x, 0.75rem);
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 700px;
  }
}

@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1140px;
  }
}

@media (min-width: 1400px) {
  .container {
    max-width: 1320px;
  }
}

.col-12 {
  flex: 0 0 auto;
  width: 100%;
}
.ps-3 {
  padding-left: 1rem !important;
}
.pe-lg-6 {
  padding-right: 2rem !important;
}

@media (max-width: 991px) {
  .fullscreen {
    display: none;
  }
  .mobilescreen {
    display: inline-block;
  }
}

@media (min-width: 992px) {
  .mobilescreen {
    display: none;
  }
  .fullscreen {
    display: inline-block;
  }
}

@media (min-width: 768px) {
  .col-md-6 {
    flex: 0 0 auto;
    width: 50%;
  }
}

@media (min-width: 992px) {
  .col-lg-6 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
}

@media (min-width: 1200px) {
  .col-xl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
}

/*
---------------------------------------------
VUSO Navbar Menu
---------------------------------------------
*/

#vusoNav {
  background: #ffffff;
  box-shadow: 0px 5px 40px rgba(0, 0, 0, 0.05);
}

#vusoNav .navbar-brand {
  width: auto;
  max-width: 75%;
}

#vusoNav .navbar-brand img {
  height: auto;
  width: 100%;
}

@media (min-width: 992px) {
  #vusoNav {
    border: none;
    transition: padding-top 0.3s ease-in-out, padding-bottom 0.3s ease-in-out;
  }

  #vusoNav .navbar-brand {
    min-width: auto;
  }
}

.vuso-navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

.vuso-navbar > .container {
  display: flex;
  flex-wrap: inherit;
  align-items: center;
  justify-content: flex-start;
}

.vuso-navbar-logo-wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 10%;
}

.vuso-navbar-status {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 90%;
}

.vuso-navbar-status p {
  font-family: "Manrope", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #1d3a60;
  padding-right: 10%;
}

@media (min-width: 576px) {
  .vuso-navbar-status p {
    font-size: 20px;
  }
}

@media (min-width: 992px) {
  .vuso-navbar-status p {
    font-size: 24px;
    padding-right: 17%;
  }

  .vuso-navbar-logo-wrapper {
    width: 15%;
  }
  .vuso-navbar-status {
    width: 85%;
  }
}

/* @media (min-width: 992px) {
.navbar-arrow{
  
}
} */

@charset "UTF-8";

/* body {
  background-color: #fbfcff;
} */

.fixed-top {
  position: fixed !important;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 700px;
  }
}

@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1140px;
  }
}

@media (min-width: 1400px) {
  .container {
    max-width: 1320px;
  }
}

.ps-3 {
  padding-left: 1rem !important;
}

/*
---------------------------------------------
Navbar Menu
---------------------------------------------
*/

.collapse:not(.show) {
  display: none;
}

.collapsing {
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}

.collapsing.collapse-horizontal {
  width: 0;
  height: auto;
  transition: width 0.35s ease;
}

nav ul {
  margin-top: 0;
  /* padding-left: 2rem; */
}

.nav-basket-mobilescreen {
  display: inherit !important;
}

.nav-basket-fullscreen {
  display: none !important;
}

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
  color: black;
  text-decoration: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out;
}

.navbar {
  padding: 10px 0;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

.navbar > .container {
  width: 100%;
  height: auto;
  padding-right: 1rem;
  padding-right: var(--bs-gutter-x, 1rem);
  padding-left: 1rem;
  padding-left: var(--bs-gutter-x, 1rem);
  margin-right: auto;
  margin-left: auto;
  display: flex;
  flex-wrap: inherit;
  align-items: center;
  justify-content: space-between;
}

.navbar-nav {
  display: flex;
  flex-direction: column;
  /* padding-left: 12px; */
  margin-bottom: 0;
  list-style: none;
}

.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: flex-start;
  flex-direction: column-reverse;
  display: flex;
}

.navbar-toggler {
  margin-left: 15px;
  padding: 0.25rem 0.75rem;
  position: relative;
  font-size: 1rem;
  line-height: 1;
  background-color: transparent;
  border: none;
  transition: color 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .nav-link {
    transition: none;
  }
  .navbar-toggler {
    transition: none;
  }
  .collapsing {
    transition: none;
  }
  .collapsing.collapse-horizontal {
    transition: none;
  }
}

#mainNav {
  background: #ffffff;
  box-shadow: 0px 5px 40px rgba(0, 0, 0, 0.05);
}

.navbar-brand-title {
  font-weight: 500 !important;
  font-size: 24px !important;
  line-height: 140% !important;
  color: #1d3a60 !important;
  vertical-align: middle !important;
  height: 100% !important;
  margin-left: 8px !important;
}

#mainNav .navbar-toggler {
  padding: 1rem;
}

#mainNav .navbar-brand {
  width: auto;
  max-width: 75%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

#mainNav .navbar-brand img {
  height: auto;
  width: 100%;
  margin-left: 16px;
}

#mainNav .navbar-nav .nav-item .nav-link {
  font-family: "Manrope", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  white-space: nowrap;
  margin-top: 15px;
  text-align: left;
}

#mainNav .navbar-nav .nav-item .nav-link.active,
#mainNav .navbar-nav .nav-item .nav-link:hover {
  color: #5d9bf8;
}

.nav-store-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  min-height: 50px;
  margin: 30px 0;
}

.nav-app-icon {
  width: 146px;
  height: 44px;
}
.nav-app-icon:last-child {
  margin-left: 1rem;
}
.nav-app-icon img {
  width: 100%;
  height: auto;
}

/* @media (min-width: 576px) {
  #mainNav .navbar-brand {
    max-width: 250px !important;
  }
} */

@media (min-width: 992px) {
  .navbar-nav {
    padding-left: 0px;
  }

  .nav-store-wrapper {
    display: none;
  }

  #mainNav .navbar-nav .nav-item .nav-link {
    margin-top: 0;
  }

  .nav-basket-mobilescreen {
    display: none !important;
  }

  .nav-basket-fullscreen {
    display: inherit !important;
  }

  #mainNav {
    border: none;
    transition: padding-top 0.3s ease-in-out, padding-bottom 0.3s ease-in-out;
  }

  #mainNav .navbar-brand {
    min-width: auto;
  }

  #mainNav.navbar-shrink {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .navbar {
    border-radius: 0px 0px 0px 0px;
  }

  .navbar-collapse {
    margin-top: 0px;
    height: auto;
    align-items: center;
  }

  .navbar-expand-lg {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
    margin-left: 6%;
    margin-top: 0px;
  }

  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 1.5rem;
    padding-left: 0rem;
  }

  .navbar-expand-lg .navbar-nav-scroll {
    overflow: visible;
  }

  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
    justify-content: space-between;
    flex-direction: row;
  }

  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
}

@media (min-width: 1200px) {
  .navbar-expand-lg .navbar-nav {
    margin-left: 11%;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 2.5rem;
    padding-left: 0rem;
  }
}

@media (min-width: 1400px) {
  .navbar-expand-lg .navbar-nav {
    margin-left: 17%;
  }
}

/*
---------------------------------------------
Trigger/burger
---------------------------------------------
*/

.menu-trigger {
  cursor: pointer;
  display: block;
  width: 26px;
  height: 26px;
  z-index: 99;
  position: absolute;
  top: -1px;
  right: 5px;
}

.menu-trigger span,
.menu-trigger span:before,
.menu-trigger span:after {
  transition: all 0.4s;
  background-color: #1d3a60;
  display: block;
  position: absolute;
  width: 20px;
  height: 2px;
  left: 0;
}

.menu-trigger span,
.menu-trigger span:before,
.menu-trigger span:after {
  background-color: #1d3a60;
}

.menu-trigger span:before,
.menu-trigger span:after {
  transition: all 0.4s;
  background-color: #1d3a60;
  display: block;
  position: absolute;
  width: 25px;
  height: 2px;
  left: 0;
  width: 75%;
}

.menu-trigger span:after {
  width: 55%;
}

.menu-trigger span:before,
.menu-trigger span:after {
  background-color: #1d3a60;
}

.menu-trigger span:before,
.menu-trigger span:after {
  content: "";
}

.menu-trigger span {
  top: 16px;
}

.menu-trigger span:before {
  -webkit-transform-origin: 40% 100%;
  transform-origin: 40% 100%;
  top: -8px;
  z-index: 10;
}

.menu-trigger span:after {
  -webkit-transform-origin: 40% 0;
  transform-origin: 40% 0;
  top: 8px;
}

.menu-trigger.active span,
.menu-trigger.active span:before,
.menu-trigger.active span:after {
  background-color: transparent;
  width: 100%;
}

.menu-trigger.active span:before {
  -webkit-transform: translateY(6px) translateX(1px) rotate(45deg);
  transform: translateY(6px) translateX(1px) rotate(45deg);
  background-color: #1d3a60;
}

.menu-trigger.active span:before {
  background-color: #1d3a60;
}

.menu-trigger.active span:after {
  -webkit-transform: translateY(-6px) translateX(1px) rotate(-45deg);
  transform: translateY(-6px) translateX(1px) rotate(-45deg);
  background-color: #1d3a60;
}

.menu-trigger.active span:after {
  background-color: #1d3a60;
}

/*
---------------------------------------------
language switch
---------------------------------------------
*/

.nav-option-wrapper {
  display: flex;
  align-items: center;
  font-family: "Manrope", sans-serif;
  padding-left: 0;
}

.nav-language {
  display: flex;
  position: relative;
  text-transform: uppercase;
  font-weight: 500;
  color: black;
  font-family: "Manrope", sans-serif;
  margin: 45px 0 25px 0;
}

.nav-language .language-toggle-outer {
  width: 100px;
  height: auto;
  cursor: pointer;
}

.nav-language .language-toggle-outer .language-toggle {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.nav-language .language-toggle-outer .language-toggle .arrow {
  width: 8px;
  height: 8px;
  display: inline-block;
  border-top: 2px solid black;
  border-right: 2px solid black;
  -webkit-transform: rotate(135deg);
          transform: rotate(135deg);
  margin-left: 9px;
  margin-bottom: 3px;
  transition: all 0.3s ease-out;
}

.nav-language .language-toggle-outer .language-toggle.open .arrow {
  margin-bottom: -4px;
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
}

.nav-language .language-select {
  position: absolute;
  left: 0;
  top: 100%;
  width: 160px;
  background: #ffffff;
  margin-top: 1px;
  padding: 20px 0px 0px 11px;
  transition: opacity 0.3s ease-out;
  opacity: 0;
  display: none;
}

.nav-language .language-select li {
  margin-bottom: 17px;
}

.language-flag {
  margin-right: 9px;
}

.language-link {
  font-style: normal;
  font-weight: 500 !important;
  font-size: 18px !important;
  line-height: 20px !important;
  color: black !important;
}

.language-select a,
.nav-basket,
.nav-basket a {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
}

.nav-language .language-select li a:hover {
  color: #5d9bf8;
}

.nav-language .language-select.block {
  opacity: 1;
  display: block;
}

.amount {
  font-family: "Manrope", sans-serif;
  font-size: 18px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: left;
  padding-left: 3px;
  color: #1d3a60;
}

.nav-basket {
  width: 50px;
  height: auto;
}

.navbar-toggler-wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100px;
  height: auto;
}

@media (min-width: 992px) {
  .navbar-toggler-wrapper {
    width: 50px;
  }

  .nav-basket {
    width: 70px;
  }
  .nav-language {
    margin: 0px 0px;
  }
  .nav-language .language-select {
    width: 100%;
  }
}

@media (min-width: 1200px) {
  .nav-basket {
    width: 100px;
  }
}

instructions {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 90%;
  height: 4810px;
  margin: 10px 0 10px 0;
  background: #ffffff;
  color: #171926;
  border-radius: 15px;
  box-shadow: 0px 9px 40px rgba(47, 32, 32, 0.11);
}
@media (min-width: 359.98px) {
  .instructions {
    height: auto;
  }
}
@media (min-width: 767.98px) {
  .instructions {
    height: 4510px;
  }
}
@media (min-width: 767.98px) and (orientation: landscape),
  (min-width: 991.98px) {
  .instructions {
    height: 4510px;
  }
}
.instructions__title {
  color: #0b0d17;
  font-size: 26px;
  text-align: center;
  line-height: 114%;
  max-width: 300px;
  margin: 15px 0px 15px 0px;
}
@media (min-width: 359.98px) {
  .instructions__title {
    font-size: 36px;
  }
}
@media (min-width: 359.98px) {
  .instructions__title {
    max-width: 100%;
  }
}
.instructions__sub-title {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 120%;
  text-align: center;
  opacity: 0.5;
}
.instructions__main-text {
  max-width: 239px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 120%;
  order: 0;
  flex-grow: 0;
  margin: 10px 0px 10px 0px;
  text-align: center;
  position: relative;
}
@media (min-width: 359.98px) {
  .instructions__main-text {
    margin: 10px 0px 10px 0px;
  }
}
@media (min-width: 359.98px) {
  .instructions__main-text {
    max-width: 100%;
  }
}
.instructions__main-text-profile::before {
  content: "";
  position: absolute;
  width: 15px;
  height: 10px;
  right: 8px;
  top: 5px;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABEAAAANCAYAAABPeYUaAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAABwSURBVHgB5ZLBCYAwEAT3LilBUH8RBNtIJ2JltpJCBPNME3om/wgh+QjO5x4HyzIsdcNsCWRQiYJ4rUjtgBhUIiCvSa4NDU1ijMdnoCSWwRaVMG5H/bicLWIjvxWbBIZwuNyveLECXuOZsiHlYt8FPgZtInsiD+K0AAAAAElFTkSuQmCC) 0 0 no-repeat;
  background-size: cover;
}
@media (min-width: 359.98px) {
  .instructions__main-text-profile::before {
    right: 4px;
  }
}
@media (min-width: 767.98px) {
  .instructions__main-text-profile::before {
    right: -22px;
  }
}
.instructions__main-text-fam::before {
  content: "";
  position: absolute;
  width: 24px;
  height: 24px;
  right: 0px;
  top: -3px;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAF5SURBVHgB7VNBTsJAFH1/aoBlj1BiMHVXTiBLghtuoJxAOUG5gdxAvUFdSeKCegLYQWRBj4A7LXS+My1ELG1SSQgbXtJM+ubPe3/m/w+ccGxQ3sbEblpl0COTcMBsqs8LibuX00Ewt9qmrIQjFWZt84UNtHiJjFEs/Dd6EbKsa7GZ3eoJwE3zaS2RZVBi8bAjrsEw9a2SH+ln8wUMVEZt5ICJHL3WpgM/iy9oQAvkOsD8D59pwCzHyNNn+aLXj1rLyeILGRjgTuYtVDENwn0cY+Auiy9kUFXdIDiqq4PeRoDBvlCdUl23aVynFI9jYGcOZnazcQZxJZkdtavemaxkhwMVHESgd46W3sXsLa5TPHTlb1cSfW7zOwZa2AC5DGqgUGbsr6JVVwtqEy6HQ9YJMcaRXHY2RrHB3L6+keAn7AEBeave/1knKCCGvxlw93zy2o+LrMR72BOSRF+vZ1+VVGuTmySQwMK+WA9YNfAWWbzAgXFwgxOOjx/udbWVF0qGdgAAAABJRU5ErkJggg==) 0 0 no-repeat;
  background-size: cover;
}
@media (min-width: 359.98px) {
  .instructions__main-text-fam::before {
    right: -7px;
  }
}
@media (min-width: 767.98px) {
  .instructions__main-text-fam::before {
    right: -28px;
  }
}
.instructions__card-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}
@media (min-width: 991.98px) {
  .instructions__card-wrapper {
    width: 100%;
  }
}
.instructions__card {
  width: 310px;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}
@media (min-width: 479.98px) {
  .instructions__card {
    width: 360px;
  }
}
@media (min-width: 767.98px) {
  .instructions__card {
    width: 450px;
  }
}
.instructions__img {
  height: 387px;
  width: 191px;
  margin: 10px 0 10px 0;
  border-radius: 5px;
}
.instructions__main-text-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}
.instructions li {
  list-style: disc;
  margin: 10px 0px 0px 0px;
  text-align: left;
}
.instructions li:last-child {
  margin: 10px 0px 20px 0px;
}

.title-wrapper {
  white-space: pre-line;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
@media (min-width: 479.98px) {
  .title-wrapper {
    width: 100%;
  }
}

.blocks-title {
  color: #171926;
  font-size: 26px;
  font-weight: 300;
  text-align: center;
  line-height: 114%;
  margin: 15px 0px 15px 0px;
}
@media (min-width: 359.98px) {
  .blocks-title {
    font-size: 32px;
  }
}
@media (min-width: 479.98px) {
  .blocks-title {
    max-width: none;
    font-size: 36px;
  }
}
@media (min-width: 767.98px) {
  .blocks-title {
    font-size: 48px;
    margin: 40px 0px 40px 0px;
  }
}

