@charset "UTF-8";

/*
---------------------------------------------
Blocks VUSO
---------------------------------------------
*/

body {
  background-color: #fbfcff;
}

.page-section-vuso {
  font-family: "Manrope", sans-serif;
  font-size: 16px;
  font-weight: 400;
  color: #3d3d3d;
  background: #ffffff;
  border: 1px solid #e4efff;
  border-radius: 32px;
  overflow-x: hidden;
}

.container-vuso {
  width: 100%;
  min-height: 350px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

@media (min-width: 992px) {
  .container-vuso {
    margin-right: auto;
    margin-left: auto;
    max-width: 960px;
    min-height: 410px;
  }
}

@media (min-width: 1200px) {
  .container-vuso {
    max-width: 1140px;
  }
}

@media (min-width: 1400px) {
  .container-vuso {
    max-width: 1320px;
  }
}

.repeat-payment {
  min-height: 325px !important;
}

.section-vuso-text {
  max-width: 90%;
  font-family: "Manrope", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 140%;
  text-align: center;
  color: #69779a;
}

.vuso-client-number,
.activation-checkin {
  font-weight: 600;
}

.activation-checkin {
  font-family: "Manrope";
  font-weight: 500;
  font-size: 20px;
  line-height: 140%;
  color: #1d3a60;
  margin-bottom: 20px;
}

.vuso-client-number {
  color: #1d3a60;
  font-weight: 800;
  font-size: 16px;
  line-height: 140%;
}

.section-vuso-icon {
  width: 72px;
  height: 72px;
}

.section-vuso-icon img {
  width: 100%;
  height: auto;
}

.btn-vuso {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 343px;
  height: 54px;
  background: #ff5e5e;
  color: #ffffff;
  border-radius: 40px;
  border: none;
  text-align: center;
  cursor: pointer;
  user-select: none;
  font-family: "Manrope", sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 16px;
  line-height: 140%;
}

.btn-vuso a {
  color: #ffffff;
  text-decoration: none;
}

.btn-vuso:hover {
  background: #fbb8b8;
}

.btn-vuso.disabled {
  pointer-events: none;
  opacity: 0.5;
}

/* form */

.verification-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.form-control {
  border: none;
  background-image: none;
  background-color: transparent;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  text-align: center;
  height: 70px;
  width: 60px;
  border-radius: 12px;
  margin: 0 6px;
  border: 1px solid #e4efff;
  font-size: 28px;
}
form .form-control:last-child {
  margin-right: 0;
}

.form-control:invalid,
.form-control:required {
  outline: none;
  border: 1px solid #ff5e5e;
}

.form-control:focus {
  outline: none;
  border: 1px solid #5d9bf8;
}

.form-control:valid {
  outline: none;
  border: 1px solid #5d9bf8;
}

.form-control:blank,
.form-control:empty {
  outline: none;
  border: 1px solid #e4efff;
}

/*
---------------------------------------------
Navbar Menu
---------------------------------------------
*/

.d-flex {
  display: flex !important;
}

.d-block {
  display: block !important;
}

.d-none {
  display: none !important;
}

.my-3 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.my-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.mt-4 {
  margin-top: 1.5rem !important;
}

.mb-3 {
  margin-bottom: 1rem !important;
}

.ms-auto {
  margin-left: auto !important;
}

.fixed-top {
  position: fixed !important;
  top: 0;
  right: 0;
  left: 0;
}

/* ol,
ul {
  padding-left: 2rem;
}

ol,
ul {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
} */

.container {
  width: 100%;
  padding-right: var(--bs-gutter-x, 0.75rem);
  padding-left: var(--bs-gutter-x, 0.75rem);
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 700px;
  }
}

@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1140px;
  }
}

@media (min-width: 1400px) {
  .container {
    max-width: 1320px;
  }
}

.col-12 {
  flex: 0 0 auto;
  width: 100%;
}
.ps-3 {
  padding-left: 1rem !important;
}
.pe-lg-6 {
  padding-right: 2rem !important;
}

@media (max-width: 991px) {
  .fullscreen {
    display: none;
  }
  .mobilescreen {
    display: inline-block;
  }
}

@media (min-width: 992px) {
  .mobilescreen {
    display: none;
  }
  .fullscreen {
    display: inline-block;
  }
}

@media (min-width: 768px) {
  .col-md-6 {
    flex: 0 0 auto;
    width: 50%;
  }
}

@media (min-width: 992px) {
  .col-lg-6 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
}

@media (min-width: 1200px) {
  .col-xl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
}

/*
---------------------------------------------
VUSO Navbar Menu
---------------------------------------------
*/

#vusoNav {
  background: #ffffff;
  box-shadow: 0px 5px 40px rgba(0, 0, 0, 0.05);
}

#vusoNav .navbar-brand {
  width: auto;
  max-width: 75%;
}

#vusoNav .navbar-brand img {
  height: auto;
  width: 100%;
}

@media (min-width: 992px) {
  #vusoNav {
    border: none;
    transition: padding-top 0.3s ease-in-out, padding-bottom 0.3s ease-in-out;
  }

  #vusoNav .navbar-brand {
    min-width: auto;
  }
}

.vuso-navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

.vuso-navbar > .container {
  display: flex;
  flex-wrap: inherit;
  align-items: center;
  justify-content: flex-start;
}

.vuso-navbar-logo-wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 10%;
}

.vuso-navbar-status {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 90%;
}

.vuso-navbar-status p {
  font-family: "Manrope", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #1d3a60;
  padding-right: 10%;
}

@media (min-width: 576px) {
  .vuso-navbar-status p {
    font-size: 20px;
  }
}

@media (min-width: 992px) {
  .vuso-navbar-status p {
    font-size: 24px;
    padding-right: 17%;
  }

  .vuso-navbar-logo-wrapper {
    width: 15%;
  }
  .vuso-navbar-status {
    width: 85%;
  }
}

/* @media (min-width: 992px) {
.navbar-arrow{
  
}
} */
