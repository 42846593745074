.wrapper {
  width: 100%;
  padding: 1rem 0rem;
  margin: 1rem 0;
  margin-bottom: 60px;
}

.text_title {
  font-size: 27px !important;
  line-height: 40px !important;
  font-weight: 800 !important;
  letter-spacing: 0em !important;
  margin: 0 0 1rem 0 !important;
  padding: 0 1rem !important;
  text-align: center !important;
  color: #ffffff !important;
}

.text_subtitle {
  font-size: 16px !important;
  font-weight: 500 !important;
  line-height: 28px !important;
  letter-spacing: 0em !important;
  text-align: center !important;
  padding: 0 1rem !important;
  color: #ffffff !important;
}

.section_4 {
  width: 100%;
  height: 700px;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-image: url(../img/background/section3_bg.png);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  border-radius: 16px;
}

.section_4 p {
  color: #ffffff;
}

.top {
  height: 35%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

.bottom {
  height: 45%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.img_s4 {
  width: 180px;
  height: 180px;
  background-image: url(../img/icon/search_image.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  margin-left: 5%;
}

@media (min-width: 768px) {
  .text_title {
    font-size: 40px !important;
    margin-bottom: 1rem !important;
    line-height: 50px !important;
  }

  .top {
    height: 35%;
  }

  .bottom {
    height: 35%;
    display: flex;
  }

  .img_s4 {
    width: 180px;
    height: 180px;
    margin-left: 10%;
  }

  .text_subtitle {
    font-size: 20px !important;
  }
}

@media (min-width: 992px) {
  .wrapper {
    margin-right: auto;
    margin-left: auto;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
    width: 90%;
    max-width: 980px;
    margin-bottom: 120px;
  }

  .text_title {
    line-height: 57px !important;
    padding: 0 3rem !important;
  }

  .text_subtitle {
    line-height: 30px !important;
    padding: 0 0 !important;
    font-size: 20px !important;
  }

  .section_4 {
    border-radius: 65px;
    height: 570px;
  }

  .img_s4 {
    width: 180px;
    height: 180px;
  }
}

@media (min-width: 1200px) {
  .text_title {
    line-height: 57px !important;
    padding: 0 5rem !important;
  }

  .wrapper {
    max-width: 1200px;
  }

  .text_subtitle {
    font-size: 20px !important;
  }
}
