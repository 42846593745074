@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@200;400;700&display=swap");

*,
*::before,
*::after {
  padding: 0;
  margin: 0;
  border: 0;
  box-sizing: border-box;
}

a {
  text-decoration: none;
  -webkit-tap-highlight-color: transparent;
  outline: none;
}

ul,
ol,
li {
  list-style: none;
}

img {
  vertical-align: top;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: inherit;
  font-size: inherit;
}

html,
body {
  line-height: 1;
  font-size: 14px;
  background: rgba(59, 18, 59, 0.067);
}

.App {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: #FBFCFF;
  overflow-x: hidden;
}

.hospital-wrapper {
  width: 1.6rem;
  height: 1.6rem;
  display: block;
  left: -0.8rem;
  top: -0.3rem;
  position: relative;
  transform: rotate(45deg);
  border-radius: 3rem 3rem 0;
  border: 1px solid #ff0000;
}

.hospital-styles {
  background-color: #ff0000;
  width: 1.5rem;
  height: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 3rem 3rem 0;
  border: 1px solid #ff0000;
}

.hospital-image {
  width: 80%;
  height: 80%;
  transform: rotate(-45deg);
}

.hospital-wrapper:hover {
  width: 1.8rem;
  height: 1.8rem;
}

.hospital-wrapper:hover .hospital-styles {
  width: 1.8rem;
  height: 1.8rem;
  background-color: #dd0000;
  border: 1px solid #dd0000;
}


.slick-dots li button::before {
  color: #fff;
  opacity: 1;
}

.slick-active button::before {
  color: #dd284a !important;
  opacity: 1;
}

.MuiListItem-root:hover {
  cursor: pointer;
}

.watches-slider .slick-slide img {
  /* display: flex;
  justify-content: center;
  align-items: center; */
  display: inline-block;
}

.markercluster-map {
  height: 90vh;
}

.icon .MuiBadge-badge {
  height: 17.5px;
  min-width: 10px;
  width: 17.5px;
}

.page__section {
  max-width: 1800px;
  min-height: 100%;
  margin: 0px auto;
}
.content-footer__logo {
  width: 100%;
  height: 10%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: whitesmoke;
  z-index: 5;
}
.content-footer__misu-logo {
  font-size: 20px;
  line-height: 30px;
  margin-right: 2%;
  font-family: "Dosis", sans-serif;
}
.content-footer__misu-img {
  height: 42px;
  width: 42px;
  margin-right: 2%;
}
.content-footer__copyright {
  font-family: "Gilroy-Regular";
  opacity: 0.7;
  font-size: 12px;
  line-height: 20px;
  text-align: left;
  color: whitesmoke;
}
.downloadApp {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}
.downloadApp ul {
  height: 250px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}
.downloadApp ul img {
  height: 55px;
  width: 190px;
}
.downloadApp__misu-logo {
  font-size: 38px;
  margin-left: 5%;
}
.downloadApp__copyright {
  margin-top: 15%;
}
