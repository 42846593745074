instructions {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 90%;
  height: 4810px;
  margin: 10px 0 10px 0;
  background: #ffffff;
  color: #171926;
  border-radius: 15px;
  box-shadow: 0px 9px 40px rgba(47, 32, 32, 0.11);
}
@media (min-width: 359.98px) {
  .instructions {
    height: auto;
  }
}
@media (min-width: 767.98px) {
  .instructions {
    height: 4510px;
  }
}
@media (min-width: 767.98px) and (orientation: landscape),
  (min-width: 991.98px) {
  .instructions {
    height: 4510px;
  }
}
.instructions__title {
  color: #0b0d17;
  font-size: 26px;
  text-align: center;
  line-height: 114%;
  max-width: 300px;
  margin: 15px 0px 15px 0px;
}
@media (min-width: 359.98px) {
  .instructions__title {
    font-size: 36px;
  }
}
@media (min-width: 359.98px) {
  .instructions__title {
    max-width: 100%;
  }
}
.instructions__sub-title {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 120%;
  text-align: center;
  opacity: 0.5;
}
.instructions__main-text {
  max-width: 239px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 120%;
  order: 0;
  flex-grow: 0;
  margin: 10px 0px 10px 0px;
  text-align: center;
  position: relative;
}
@media (min-width: 359.98px) {
  .instructions__main-text {
    margin: 10px 0px 10px 0px;
  }
}
@media (min-width: 359.98px) {
  .instructions__main-text {
    max-width: 100%;
  }
}
.instructions__main-text-profile::before {
  content: "";
  position: absolute;
  width: 15px;
  height: 10px;
  right: 8px;
  top: 5px;
  background: url(../image/instruction-icon-1.png) 0 0 no-repeat;
  background-size: cover;
}
@media (min-width: 359.98px) {
  .instructions__main-text-profile::before {
    right: 4px;
  }
}
@media (min-width: 767.98px) {
  .instructions__main-text-profile::before {
    right: -22px;
  }
}
.instructions__main-text-fam::before {
  content: "";
  position: absolute;
  width: 24px;
  height: 24px;
  right: 0px;
  top: -3px;
  background: url(../image/instruction-icon-2.png) 0 0 no-repeat;
  background-size: cover;
}
@media (min-width: 359.98px) {
  .instructions__main-text-fam::before {
    right: -7px;
  }
}
@media (min-width: 767.98px) {
  .instructions__main-text-fam::before {
    right: -28px;
  }
}
.instructions__card-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}
@media (min-width: 991.98px) {
  .instructions__card-wrapper {
    width: 100%;
  }
}
.instructions__card {
  width: 310px;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}
@media (min-width: 479.98px) {
  .instructions__card {
    width: 360px;
  }
}
@media (min-width: 767.98px) {
  .instructions__card {
    width: 450px;
  }
}
.instructions__img {
  height: 387px;
  width: 191px;
  margin: 10px 0 10px 0;
  border-radius: 5px;
}
.instructions__main-text-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}
.instructions li {
  list-style: disc;
  margin: 10px 0px 0px 0px;
  text-align: left;
}
.instructions li:last-child {
  margin: 10px 0px 20px 0px;
}

.title-wrapper {
  white-space: pre-line;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
@media (min-width: 479.98px) {
  .title-wrapper {
    width: 100%;
  }
}

.blocks-title {
  color: #171926;
  font-size: 26px;
  font-weight: 300;
  text-align: center;
  line-height: 114%;
  margin: 15px 0px 15px 0px;
}
@media (min-width: 359.98px) {
  .blocks-title {
    font-size: 32px;
  }
}
@media (min-width: 479.98px) {
  .blocks-title {
    max-width: none;
    font-size: 36px;
  }
}
@media (min-width: 767.98px) {
  .blocks-title {
    font-size: 48px;
    margin: 40px 0px 40px 0px;
  }
}
