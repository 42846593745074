@charset "UTF-8";

/* body {
  background-color: #fbfcff;
} */

.fixed-top {
  position: fixed !important;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 700px;
  }
}

@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1140px;
  }
}

@media (min-width: 1400px) {
  .container {
    max-width: 1320px;
  }
}

.ps-3 {
  padding-left: 1rem !important;
}

/*
---------------------------------------------
Navbar Menu
---------------------------------------------
*/

.collapse:not(.show) {
  display: none;
}

.collapsing {
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}

.collapsing.collapse-horizontal {
  width: 0;
  height: auto;
  transition: width 0.35s ease;
}

nav ul {
  margin-top: 0;
  /* padding-left: 2rem; */
}

.nav-basket-mobilescreen {
  display: inherit !important;
}

.nav-basket-fullscreen {
  display: none !important;
}

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
  color: black;
  text-decoration: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out;
}

.navbar {
  padding: 10px 0;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

.navbar > .container {
  width: 100%;
  height: auto;
  padding-right: var(--bs-gutter-x, 1rem);
  padding-left: var(--bs-gutter-x, 1rem);
  margin-right: auto;
  margin-left: auto;
  display: flex;
  flex-wrap: inherit;
  align-items: center;
  justify-content: space-between;
}

.navbar-nav {
  display: flex;
  flex-direction: column;
  /* padding-left: 12px; */
  margin-bottom: 0;
  list-style: none;
}

.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: flex-start;
  flex-direction: column-reverse;
  display: flex;
}

.navbar-toggler {
  margin-left: 15px;
  padding: 0.25rem 0.75rem;
  position: relative;
  font-size: 1rem;
  line-height: 1;
  background-color: transparent;
  border: none;
  transition: color 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .nav-link {
    transition: none;
  }
  .navbar-toggler {
    transition: none;
  }
  .collapsing {
    transition: none;
  }
  .collapsing.collapse-horizontal {
    transition: none;
  }
}

#mainNav {
  background: #ffffff;
  box-shadow: 0px 5px 40px rgba(0, 0, 0, 0.05);
}

.navbar-brand-title {
  font-weight: 500 !important;
  font-size: 24px !important;
  line-height: 140% !important;
  color: #1d3a60 !important;
  vertical-align: middle !important;
  height: 100% !important;
  margin-left: 8px !important;
}

#mainNav .navbar-toggler {
  padding: 1rem;
}

#mainNav .navbar-brand {
  width: auto;
  max-width: 75%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

#mainNav .navbar-brand img {
  height: auto;
  width: 100%;
  margin-left: 16px;
}

#mainNav .navbar-nav .nav-item .nav-link {
  font-family: "Manrope", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  white-space: nowrap;
  margin-top: 15px;
  text-align: left;
}

#mainNav .navbar-nav .nav-item .nav-link.active,
#mainNav .navbar-nav .nav-item .nav-link:hover {
  color: #5d9bf8;
}

.nav-store-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  min-height: 50px;
  margin: 30px 0;
}

.nav-app-icon {
  width: 146px;
  height: 44px;
}
.nav-app-icon:last-child {
  margin-left: 1rem;
}
.nav-app-icon img {
  width: 100%;
  height: auto;
}

/* @media (min-width: 576px) {
  #mainNav .navbar-brand {
    max-width: 250px !important;
  }
} */

@media (min-width: 992px) {
  .navbar-nav {
    padding-left: 0px;
  }

  .nav-store-wrapper {
    display: none;
  }

  #mainNav .navbar-nav .nav-item .nav-link {
    margin-top: 0;
  }

  .nav-basket-mobilescreen {
    display: none !important;
  }

  .nav-basket-fullscreen {
    display: inherit !important;
  }

  #mainNav {
    border: none;
    transition: padding-top 0.3s ease-in-out, padding-bottom 0.3s ease-in-out;
  }

  #mainNav .navbar-brand {
    min-width: auto;
  }

  #mainNav.navbar-shrink {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .navbar {
    border-radius: 0px 0px 0px 0px;
  }

  .navbar-collapse {
    margin-top: 0px;
    height: auto;
    align-items: center;
  }

  .navbar-expand-lg {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
    margin-left: 6%;
    margin-top: 0px;
  }

  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 1.5rem;
    padding-left: 0rem;
  }

  .navbar-expand-lg .navbar-nav-scroll {
    overflow: visible;
  }

  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
    justify-content: space-between;
    flex-direction: row;
  }

  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
}

@media (min-width: 1200px) {
  .navbar-expand-lg .navbar-nav {
    margin-left: 11%;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 2.5rem;
    padding-left: 0rem;
  }
}

@media (min-width: 1400px) {
  .navbar-expand-lg .navbar-nav {
    margin-left: 17%;
  }
}

/*
---------------------------------------------
Trigger/burger
---------------------------------------------
*/

.menu-trigger {
  cursor: pointer;
  display: block;
  width: 26px;
  height: 26px;
  z-index: 99;
  position: absolute;
  top: -1px;
  right: 5px;
}

.menu-trigger span,
.menu-trigger span:before,
.menu-trigger span:after {
  -moz-transition: all 0.4s;
  -o-transition: all 0.4s;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
  background-color: #1d3a60;
  display: block;
  position: absolute;
  width: 20px;
  height: 2px;
  left: 0;
}

.menu-trigger span,
.menu-trigger span:before,
.menu-trigger span:after {
  background-color: #1d3a60;
}

.menu-trigger span:before,
.menu-trigger span:after {
  -moz-transition: all 0.4s;
  -o-transition: all 0.4s;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
  background-color: #1d3a60;
  display: block;
  position: absolute;
  width: 25px;
  height: 2px;
  left: 0;
  width: 75%;
}

.menu-trigger span:after {
  width: 55%;
}

.menu-trigger span:before,
.menu-trigger span:after {
  background-color: #1d3a60;
}

.menu-trigger span:before,
.menu-trigger span:after {
  content: "";
}

.menu-trigger span {
  top: 16px;
}

.menu-trigger span:before {
  -moz-transform-origin: 40% 100%;
  -ms-transform-origin: 40% 100%;
  -webkit-transform-origin: 40% 100%;
  transform-origin: 40% 100%;
  top: -8px;
  z-index: 10;
}

.menu-trigger span:after {
  -moz-transform-origin: 40% 0;
  -ms-transform-origin: 40% 0;
  -webkit-transform-origin: 40% 0;
  transform-origin: 40% 0;
  top: 8px;
}

.menu-trigger.active span,
.menu-trigger.active span:before,
.menu-trigger.active span:after {
  background-color: transparent;
  width: 100%;
}

.menu-trigger.active span:before {
  -moz-transform: translateY(6px) translateX(1px) rotate(45deg);
  -ms-transform: translateY(6px) translateX(1px) rotate(45deg);
  -webkit-transform: translateY(6px) translateX(1px) rotate(45deg);
  transform: translateY(6px) translateX(1px) rotate(45deg);
  background-color: #1d3a60;
}

.menu-trigger.active span:before {
  background-color: #1d3a60;
}

.menu-trigger.active span:after {
  -moz-transform: translateY(-6px) translateX(1px) rotate(-45deg);
  -ms-transform: translateY(-6px) translateX(1px) rotate(-45deg);
  -webkit-transform: translateY(-6px) translateX(1px) rotate(-45deg);
  transform: translateY(-6px) translateX(1px) rotate(-45deg);
  background-color: #1d3a60;
}

.menu-trigger.active span:after {
  background-color: #1d3a60;
}

/*
---------------------------------------------
language switch
---------------------------------------------
*/

.nav-option-wrapper {
  display: flex;
  align-items: center;
  font-family: "Manrope", sans-serif;
  padding-left: 0;
}

.nav-language {
  display: flex;
  position: relative;
  text-transform: uppercase;
  font-weight: 500;
  color: black;
  font-family: "Manrope", sans-serif;
  margin: 45px 0 25px 0;
}

.nav-language .language-toggle-outer {
  width: 100px;
  height: auto;
  cursor: pointer;
}

.nav-language .language-toggle-outer .language-toggle {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.nav-language .language-toggle-outer .language-toggle .arrow {
  width: 8px;
  height: 8px;
  display: inline-block;
  border-top: 2px solid black;
  border-right: 2px solid black;
  transform: rotate(135deg);
  margin-left: 9px;
  margin-bottom: 3px;
  transition: all 0.3s ease-out;
}

.nav-language .language-toggle-outer .language-toggle.open .arrow {
  margin-bottom: -4px;
  transform: rotate(-45deg);
}

.nav-language .language-select {
  position: absolute;
  left: 0;
  top: 100%;
  width: 160px;
  background: #ffffff;
  margin-top: 1px;
  padding: 20px 0px 0px 11px;
  transition: opacity 0.3s ease-out;
  opacity: 0;
  display: none;
}

.nav-language .language-select li {
  margin-bottom: 17px;
}

.language-flag {
  margin-right: 9px;
}

.language-link {
  font-style: normal;
  font-weight: 500 !important;
  font-size: 18px !important;
  line-height: 20px !important;
  color: black !important;
}

.language-select a,
.nav-basket,
.nav-basket a {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
}

.nav-language .language-select li a:hover {
  color: #5d9bf8;
}

.nav-language .language-select.block {
  opacity: 1;
  display: block;
}

.amount {
  font-family: "Manrope", sans-serif;
  font-size: 18px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: left;
  padding-left: 3px;
  color: #1d3a60;
}

.nav-basket {
  width: 50px;
  height: auto;
}

.navbar-toggler-wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100px;
  height: auto;
}

@media (min-width: 992px) {
  .navbar-toggler-wrapper {
    width: 50px;
  }

  .nav-basket {
    width: 70px;
  }
  .nav-language {
    margin: 0px 0px;
  }
  .nav-language .language-select {
    width: 100%;
  }
}

@media (min-width: 1200px) {
  .nav-basket {
    width: 100px;
  }
}
